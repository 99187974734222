// src/features/invitation/components/NoVisitsMessage.tsx

import { Paper, Typography } from '@mui/material';
import { CalendarToday } from '@mui/icons-material';

const NoVisitsMessage = ({ isActiveTab }: { isActiveTab?: boolean }) => (
  <Paper elevation={0} sx={{ p: 4, textAlign: 'center', backgroundColor: 'background.default', borderRadius: 4 }}>
    <CalendarToday sx={{ fontSize: 60, color: 'text.secondary', mb: 2 }} />
    <Typography variant="h6" gutterBottom>
      {isActiveTab ? "No tienes visitas activas" : "No hay visitas en el historial"}
    </Typography>
    <Typography variant="body2" color="text.secondary">
      {isActiveTab
        ? "Cuando tengas visitas programadas, aparecerán aquí."
        : "Cuando hayas tenido visitas, aparecerán en este historial."}
    </Typography>
  </Paper>
);

export default NoVisitsMessage;