// src/features/incidents/components/detail/IncidentComments.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  TextField,
  Avatar,
  Stack,
  Typography,
  Divider,
  IconButton,
  Alert,
  Snackbar
} from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import { Send, Image } from 'lucide-react';
import { MediaUpload } from '../create';
import { MediaGallery } from '../shared/MediaGallery';

interface User {
  first_name: string;
  last_name: string;
  profile_pic?: string;
}

interface Comment {
  id: string;
  comment: string;
  created_at: string;
  user: User;
  media_urls?: string[];
}

interface IncidentCommentsProps {
  comments: Comment[];
  onAddComment: (comment: string, mediaFiles: File[]) => Promise<void>;
  incidentId: string;
  buildingId: string;
  loading?: boolean;
  readonly?: boolean;
}

const IncidentComments = React.memo(({
  comments = [],
  onAddComment,
  incidentId,
  buildingId,
  loading = false
}: IncidentCommentsProps) => {
  const [comment, setComment] = useState<string>('');
  const [mediaFiles, setMediaFiles] = useState<File[]>([]);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit = useCallback(async (e: any) => {
    e.preventDefault();
    const trimmedComment = comment.trim();

    if (!trimmedComment && mediaFiles.length === 0) return;
    if (!buildingId)
    {
      setError('Missing building ID');
      return;
    }

    try
    {
      setError(null);
      console.log('Submitting comment:', {
        comment: trimmedComment,
        mediaFiles,
        incidentId,
        buildingId
      });

      await onAddComment(trimmedComment, mediaFiles);
      setComment('');
      setMediaFiles([]);
    } catch (error)
    {
      console.error('Error adding comment:', error);
      setError(error instanceof Error ? error.message : 'Error al agregar el comentario');
    }
  }, [comment, mediaFiles, onAddComment, incidentId, buildingId]);

  const handleFileSelect = useCallback((e: any) => {
    const newFiles: File[] = Array.from(e.target.files);
    setMediaFiles(prevFiles => [...prevFiles, ...newFiles]);
    e.target.value = '';
  }, []);

  // Debugging comments rendering
  useEffect(() => {
    console.log('Comments updated:', comments);
  }, [comments]);

  return (
    <Stack spacing={3} sx={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
      <Typography variant="h6">
        Comentarios ({comments.length})
      </Typography>

      {/* Comment List */}
      <Stack spacing={2}>
        {[...comments]
          .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
          .map((comment, index) => (
            <Box key={comment.id}>
              <Stack direction="row" spacing={2}>
                <Avatar
                  src={comment.user.profile_pic}
                  alt={comment.user.first_name}
                />
                <Stack spacing={1} flex={1}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="subtitle2">
                      {comment.user.first_name} {comment.user.last_name}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {formatDistanceToNow(new Date(comment.created_at), {
                        addSuffix: true,
                        locale: es
                      })}
                    </Typography>
                  </Box>
                  <Typography variant="body2">{comment.comment}</Typography>
                  {comment.media_urls && comment.media_urls.length > 0 && (
                    <Box sx={{ width: '100%', maxWidth: '100%', overflow: 'hidden', mt: 1 }}>
                      <MediaGallery media={comment.media_urls} />
                    </Box>
                  )}
                </Stack>
              </Stack>
              {index < comments.length - 1 && <Divider sx={{ my: 2 }} />}
            </Box>
          ))}
      </Stack>

      {/* Comment Form */}
      <Box component="form" onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            multiline
            rows={2}
            placeholder="Escribe un comentario..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            disabled={loading}
            error={!!error}
            helperText={error}
            InputProps={{
              endAdornment: (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <input
                    type="file"
                    multiple
                    hidden
                    ref={fileInputRef}
                    onChange={handleFileSelect}
                    accept="image/*,video/*"
                  />
                  <IconButton
                    onClick={() => fileInputRef.current?.click()}
                    color={mediaFiles.length > 0 ? 'primary' : 'default'}
                  >
                    <Image size={20} />
                  </IconButton>
                  <IconButton
                    type="submit"
                    disabled={loading || (!comment.trim() && mediaFiles.length === 0)}
                  >
                    <Send size={20} />
                  </IconButton>
                </Box>
              )
            }}
          />

          {mediaFiles.length > 0 && (
            <MediaUpload
              files={mediaFiles}
              onChange={setMediaFiles}
              disabled={loading}
              prefix={`incidents/${incidentId}/comments`}
              hideAttachButton={true}
            />
          )}
        </Stack>
      </Box>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </Stack>
  );
});

export default IncidentComments;
