// src/shared/hooks/useAnalytics.ts

import { useCallback } from 'react';
import { 
  trackEvent, 
  trackPageView as trackPageViewAnalytics,
  identifyUser, 
  resetUser, 
  isFeatureEnabled, 
  getFeatureFlagValue, 
  setAnalyticsGroup 
} from '..';
import { EVENT_TYPES } from '../config/events';
import { normalizeUrl } from '../../shared/utils/urlNormalizer';

type AnalyticsProperties = Record<string, any>; 

export const useAnalytics = () => {
  const track = useCallback((eventName: string, properties: AnalyticsProperties = {}) => {
    trackEvent(eventName, properties);
  }, []);

  const trackPageView = useCallback((path: string, additionalProperties: AnalyticsProperties = {}) => {
    const normalizedPath = normalizeUrl(path);
    trackPageViewAnalytics(normalizedPath, additionalProperties);
  }, []);

  const identify = useCallback((userId: string, traits: AnalyticsProperties = {}) => {
    identifyUser(userId, traits);
  }, []);

  const reset = useCallback(() => {
    resetUser();
  }, []);

  const checkFeatureFlag = useCallback((flagKey: string, defaultValue: boolean = false) => {
    return isFeatureEnabled(flagKey, defaultValue);
  }, []);

  const getFeatureFlag = useCallback((flagKey: string) => {
    return getFeatureFlagValue(flagKey);
  }, []);

  const setGroup = useCallback((groupType: string, groupKey: string, groupProperties: AnalyticsProperties = {}) => {
    setAnalyticsGroup(groupType, groupKey, groupProperties);
  }, []);

  const trackError = useCallback((errorMessage: string, errorCode: string | number) => {
    track(EVENT_TYPES.ERROR_OCCURRED, { errorMessage, errorCode });
  }, [track]);

  const trackUserAction = useCallback((actionType: string, actionProperties: AnalyticsProperties = {}) => {
    track(actionType, actionProperties);
  }, [track]);

  return {
    track,
    trackPageView,
    identify,
    reset,
    checkFeatureFlag,
    getFeatureFlag,
    setGroup,
    trackError,
    trackUserAction
  };
};