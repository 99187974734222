import { create } from "zustand";
import { authTokenService } from "../features/auth/services/authTokenService";
import { authService } from "../features/auth/services/authService";
import { supabase } from "../supabase/client";

export interface User {
  id: string;
  email: string;
  [key: string]: any;
}

export type State = {
  user: any;
  loading: boolean;
  error: any;
  setUser: (user: any) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: any) => void;

  invitationToken: string | null;
  buildingId: string | null;
  apartmentId: string | null;
  setInvitationToken: (token: string | null) => void;
  setBuildingId: (id: string | null) => void;
  setApartmentId: (id: string | null) => void;

  fetchUserProfile: (authUser: any) => Promise<void>;
  initializeUser: () => Promise<void>;
  clearContext: () => void;
}

export const useGlobalStore = create<State>((set, get) => ({
  // User State
  user: null,
  loading: true,
  error: null,

  // App State
  invitationToken: null,
  buildingId: null,
  apartmentId: null,

  // User Actions
  setUser: (user) => set({ user }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),

  fetchUserProfile: async (authUser: any) => {
    if (!authUser)
    {
      set({ user: null, loading: false });
      return;
    }

    try
    {
      set({ error: null });
      const userProfile = await authTokenService.retryOperation(() =>
        authService.getUserProfile(authUser.id)
      );

      const residenceStructure = await authTokenService.retryOperation(() =>
        authService.getUserResidenceStructure(userProfile.id)
      );

      const mergedUser = {
        ...authUser,
        ...userProfile,
        buildings: residenceStructure,
      };

      set({ user: mergedUser });
    } catch (error)
    {
      set({ error, user: authUser });
    } finally
    {
      set({ loading: false });
    }
  },

  initializeUser: async () => {
    const { data: { session } } = await supabase.auth.getSession();

    if (session)
    {
      await get().fetchUserProfile(session.user);
    } else
    {
      set({ user: null, loading: false });
    }

    supabase.auth.onAuthStateChange(async (event, session) => {
      if (session)
      {
        await get().fetchUserProfile(session.user);
      } else
      {
        set({ user: null, loading: false });
      }
    });
  },

  // App Actions
  setInvitationToken: (token) => set({ invitationToken: token }),
  setBuildingId: (id) => set({ buildingId: id }),
  setApartmentId: (id) => set({ apartmentId: id }),
  clearContext: () => set({ invitationToken: null, buildingId: null, apartmentId: null }),
}));

export default useGlobalStore;