// src/analytics/config/testConfig.ts

export type Environment = 'production' | 'development' | 'preview' | 'unknown';

type TestEmails = {
  DEV: string;
  UI: string;
  QA: string;
  DEMO: string;
  INVITATION: string;
  REGISTER: string;
  RESIDENT: string;
  VISIT: string;
  ADMIN: string;
  SECURITY: string;
  CONCIERGE: string;
};

type Domains = {
  PRODUCTION: string;
  PREVIEW: RegExp;
  LOCAL: RegExp;
};

// Estructura principal
export interface TestConfig {
  TEST_EMAILS: TestEmails;
  DOMAINS: Domains;
  TEST_EMAIL_PATTERNS: RegExp[];
}

export const TEST_CONFIG: TestConfig = {
    // Email patterns for testing
    TEST_EMAILS: {
      DEV: 'test+dev@appdomus.io',
      UI: 'test+ui@appdomus.io',
      QA: 'test+qa@appdomus.io',
      DEMO: 'test+demo@appdomus.io',
      // Feature specific
      INVITATION: 'test+invitation@appdomus.io',
      REGISTER: 'test+register@appdomus.io',
      RESIDENT: 'test+resident@appdomus.io',
      VISIT: 'test+visit@appdomus.io',
      // Roles
      ADMIN: 'test+admin@appdomus.io',
      SECURITY: 'test+security@appdomus.io',
      CONCIERGE: 'test+concierge@appdomus.io'
    },
  
    // Domains for different environments
    DOMAINS: {
      PRODUCTION: 'residente.appdomus.io',
      PREVIEW: /residente.*\.vercel\.app/,
      LOCAL: /localhost|127\.0\.0\.1/
    },
  
    // Test email patterns to filter
    TEST_EMAIL_PATTERNS: [
      /@appdomus\.io$/,
      /^test\+/,
      /^dev\+/,
      /^qa\+/
    ]
  };
  
  // Helper functions
  export const isTestEmail = (email: string | null | undefined) : boolean => {
    if (!email) return false;
    return TEST_CONFIG.TEST_EMAIL_PATTERNS.some(pattern => pattern.test(email));
  };
  
  export const getEnvironment = (): Environment => {
    const hostname = window.location.hostname;
    if (hostname === TEST_CONFIG.DOMAINS.PRODUCTION) return 'production';
    if (TEST_CONFIG.DOMAINS.LOCAL.test(hostname)) return 'development';
    if (TEST_CONFIG.DOMAINS.PREVIEW.test(hostname)) return 'preview';
    return 'unknown';
  };