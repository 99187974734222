import { invitationService } from '../services/invitationService';
import { useInvitationAnalytics } from './useInvitationAnalytics';
import { useNavigation } from '../../../navigation/hooks/useNavigation';
import { useAuth } from '../../auth/hooks/useAuth';
import useGlobalStore from '../../../state/store';

export const useInvitation = () => {
  const invitationToken = useGlobalStore((state) => state.invitationToken);
  const setInvitationToken = useGlobalStore((state) => state.setInvitationToken);

  const { trackVisitCreated } = useInvitationAnalytics();
  const { clearParams } = useNavigation();
  const { user } = useAuth();

  const createVisitFromInvitation = async () => {
    if (!invitationToken || !user) {
      throw new Error('No invitation token or user available');
    }
    
    try {
      const { visitId, isExisting } = await invitationService.createVisitFromInvitation(invitationToken, user.id);
      
      if (!isExisting) {
        trackVisitCreated(invitationToken, user.id);
      }

      clearParams();
      return { visitId, isExisting };
    } catch (error) {
      console.error('Error creating visit from invitation:', error);
      throw error;
    }
  };

  return {
    invitationToken,
    setInvitationToken,
    createVisitFromInvitation
  };
};