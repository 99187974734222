// src/features/building/pages/BuildingLanding.tsx

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Avatar,
  Stack,
  CircularProgress,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { LocationOn, Business } from '@mui/icons-material';
import { useBuilding } from '../hooks/useBuilding';
import { fetchBuildingDetails } from '../services/buildingService';
import { useAuth } from '../../../features/auth/hooks/useAuth';
import { useNavigation } from '../../../navigation/hooks/useNavigation';
import { ROUTES } from '../../../config/routes';
import { useBuildingAnalytics } from '../hooks/useBuildingAnalytics';

// Define types for Building and Route Params
interface Building {
  id: string;
  name: string;
  address: string;
  [key: string]: any; // Extendable for additional building properties
}

interface Params {
  [key: string]: string | undefined;
  buildingId?: string;
}

const BuildingLanding: React.FC = () => {
  const { buildingId } = useParams<Params>();
  const { setBuildingId } = useBuilding();
  const [building, setBuilding] = useState<Building | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();
  const { navigateWithParams } = useNavigation();
  const { trackBuildingViewed } = useBuildingAnalytics();

  useEffect(() => {
    if (buildingId) {
      setBuildingId(buildingId);
    }

    const fetchBuildingData = async () => {
      if (!buildingId) {
        setError('No se proporcionó un ID de edificio.');
        setLoading(false);
        return;
      }

      try {
        const data = await fetchBuildingDetails(buildingId);
        setBuilding(data as Building | null);

        if (user) {
          trackBuildingViewed(buildingId, user.id);
        }
      } catch (err: any) {
        console.error('Error fetching building:', err);
        setError(err.message || 'Ocurrió un error al obtener los detalles del edificio');
      } finally {
        setLoading(false);
      }
    };

    fetchBuildingData();
  }, [buildingId, setBuildingId, user, trackBuildingViewed]);

  const handleContinue = () => {
    if (buildingId) {
      navigateWithParams(ROUTES.APARTMENT_SELECTION, { buildingId });
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error}</Typography>;
  if (!building) return <Typography>Edificio no encontrado.</Typography>;

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', p: 2 }}>
      <Typography variant="h4" gutterBottom>Bienvenido</Typography>
      <Typography variant="body1" paragraph>
        Has sido invitado a registrarte en este edificio. {user ? 'Continúa para seleccionar tu apartamento.' : 'Para continuar, inicia sesión o regístrate.'}
      </Typography>
      <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>Detalles del Edificio:</Typography>
        <Stack direction="row" spacing={2} alignItems="center" mb={2}>
          <Avatar sx={{ bgcolor: 'primary.main' }}>
            <Business />
          </Avatar>
          <Box>
            <Typography variant="subtitle1">{building.name}</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <LocationOn fontSize="small" />
              <Typography variant="body2">{building.address}</Typography>
            </Stack>
          </Box>
        </Stack>
      </Box>
      {user ? (
        <Button
          onClick={handleContinue}
          variant="contained"
          fullWidth
        >
          Continuar
        </Button>
      ) : (
        <>
          <Button
            component={Link}
            to={`${ROUTES.LOGIN}?buildingId=${buildingId}`}
            variant="contained"
            fullWidth
            sx={{ mb: 2 }}
          >
            Iniciar sesión
          </Button>
          <Button
            component={Link}
            to={`${ROUTES.REGISTER}?buildingId=${buildingId}`}
            variant="outlined"
            fullWidth
          >
            Soy nuevo, registrarme
          </Button>
        </>
      )}
      <Typography variant="body2" align="center" sx={{ mt: 2 }}>
        Al iniciar sesión o registrarse, acepta nuestros{' '}
        <Link to={ROUTES.TERMS} target="_blank" rel="noopener noreferrer">
          Términos de servicio
        </Link>{' '}
        y{' '}
        <Link to={ROUTES.PRIVACY} target="_blank" rel="noopener noreferrer">
          Política de Privacidad
        </Link>
      </Typography>
    </Box>
  );
};

export default BuildingLanding;
