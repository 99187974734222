import { useState, useEffect, useCallback } from 'react';
import { Box, Tabs, Tab, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { invitationService } from '../services/invitationService';
import VisitList from '../components/VisitList';
import { isToday, isFuture, parseISO, compareAsc, compareDesc } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { ROUTES } from '../../../config/routes';

const VisitMain = () => {
  const [tabValue, setTabValue] = useState(0);
  const [visits, setVisits] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const isActiveVisit = useCallback((visit: any) => {
    if (!visit?.invitation?.date) return false;
    const visitDate = toDate(parseISO(visit.invitation.date), { timeZone: userTimeZone });
    return isToday(visitDate) || isFuture(visitDate);
  }, [userTimeZone]);

  const fetchVisits = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await invitationService.fetchUserVisits();
      setVisits(data);
    } catch (err) {
      console.error('Error fetching visits:', err);
      setError('Failed to fetch visits. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchVisits();
  }, [fetchVisits]);

  const handleTabChange = (_event: any, newValue: number) => {
    setTabValue(newValue);
  };

  const handleVisitClick = (visitId: any) => {
    navigate(ROUTES.VISIT_DETAIL.replace(':visitId', visitId));
  };

  const getFilteredAndSortedVisits = useCallback(() => {
    if (!Array.isArray(visits)) return [];
    
    return visits
      .filter(visit => visit?.invitation?.date)
      .filter(visit => tabValue === 0 ? isActiveVisit(visit) : !isActiveVisit(visit))
      .sort((a, b) => {
        const dateA = toDate(parseISO(a.invitation.date), { timeZone: userTimeZone });
        const dateB = toDate(parseISO(b.invitation.date), { timeZone: userTimeZone });
        return tabValue === 0 ? compareAsc(dateA, dateB) : compareDesc(dateA, dateB);
      });
  }, [visits, tabValue, isActiveVisit, userTimeZone]);

  if (loading) return (
    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
      <CircularProgress />
    </Box>
  );
  
  if (error) return (
    <Box sx={{ p: 3, textAlign: 'center' }}>
      <Typography color="error">{error}</Typography>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Tabs 
        value={tabValue} 
        onChange={handleTabChange} 
        aria-label="visit tabs"
        variant="fullWidth"
        centered
      >
        <Tab label="Activas" />
        <Tab label="Historial" />
      </Tabs>
      <Box sx={{ px: 1 }}>
        <VisitList 
          visits={getFilteredAndSortedVisits()}
          isActiveTab={tabValue === 0}
          onVisitClick={handleVisitClick}
        />
      </Box>
    </Box>
  );
};

export default VisitMain;
