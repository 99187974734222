import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './styles/theme';
import MainLayout from './shared/layouts/MainLayout';
import AuthLayout from './shared/layouts/AuthLayout';

import { NavigationProvider } from './navigation/context/NavigationContext';

import Login from './features/auth/pages/Login';
import Register from './features/auth/pages/Register';
import ForgotPassword from './features/auth/pages/ForgotPassword';
import ResetPassword from './features/auth/pages/ResetPassword';

import Dashboard from './features/dashboard/pages/Dashboard';
import NotFound from './shared/components/NotFound';
import { PrivateRoute, AuthRoute, PublicAuthRoute } from './navigation/components/PrivateRoute';

import InvitationLanding from './features/invitation/pages/InvitationLanding';
import VisitDetail from './features/invitation/pages/VisitDetail';

import BuildingLanding from './features/building/pages/BuildingLanding';
import ApartmentSelection from './features/building/pages/ApartmentSelection';

import CreateIncident from './features/incidents/pages/CreateIncident';
import IncidentDetail from './features/incidents/pages/IncidentDetail';
import IncidentMain from './features/incidents/pages/IncidentMain';

import { ROUTES } from './config/routes';
import { initAnalytics } from './analytics';
import AnalyticsWrapper from './analytics/components/AnalyticsWrapper';
import useGlobalStore from './state/store';


function App() {
  const initializeStore = useGlobalStore((state) => state.initializeUser);

  useEffect(() => {
    initAnalytics();
    initializeStore();
  }, [initializeStore]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <NavigationProvider>
          <AnalyticsWrapper>
            <Routes>
              {/* Auth-only routes that redirect to dashboard if logged in */}
              <Route element={<AuthRoute><AuthLayout /></AuthRoute>}>
                <Route path={ROUTES.LOGIN} element={<Login />} />
                <Route path={ROUTES.REGISTER} element={<Register />} />
                <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
                <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
              </Route>

              {/* Public routes that work both logged-in and logged-out */}
              <Route element={<PublicAuthRoute><AuthLayout /></PublicAuthRoute>}>
                <Route path={ROUTES.BUILDING_LANDING} element={<BuildingLanding />} />
                <Route path={ROUTES.INVITATION} element={<InvitationLanding />} />
              </Route>

              {/* Private routes */}
              <Route element={<PrivateRoute />}>
                <Route element={<MainLayout />}>
                  <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
                  <Route path={ROUTES.VISIT_DETAIL} element={<VisitDetail />} />
                  <Route path={ROUTES.APARTMENT_SELECTION} element={<ApartmentSelection />} />

                  <Route path={ROUTES.INCIDENTS} element={<IncidentMain />} />
                  <Route path={ROUTES.INCIDENT_NEW} element={<CreateIncident />} />
                  <Route path={ROUTES.INCIDENT_DETAIL} element={<IncidentDetail />} />
                </Route>
              </Route>

              <Route path="/" element={<Navigate replace to={ROUTES.LOGIN} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AnalyticsWrapper>
        </NavigationProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;