// src/features/incidents/services/storageService.ts

import { supabaseStorage } from '../../../storage';

// Define types for input parameters and return values
type UploadOptions = {
  buildingId: string;
  incidentId: string;
  type?: 'main' | 'comments' | 'resolution';
  subId?: string | null;
};

type UploadedFile = {
  url: string;
  path: string;
  size: number;
  type: string;
  name: string;
};

export const incidentStorageService = {
  generatePath(buildingId: string, incidentId: string, type: 'main' | 'comments' | 'resolution' = 'main', subId: string | null = null): string {
    if (!buildingId || !incidentId) {
      throw new Error('Both buildingId and incidentId are required');
    }

    const basePath = `incidents/${buildingId}/${incidentId}`;
    switch (type) {
      case 'main':
        return `${basePath}/main`;
      case 'comments':
        if (!subId) {
          throw new Error('Comment ID (subId) is required for comment attachments');
        }
        return `${basePath}/comments/${subId}`;
      case 'resolution':
        return `${basePath}/resolution`;
      default:
        return basePath;
    }
  },

  async uploadFile(file: File, options: UploadOptions): Promise<UploadedFile> {
    try {
      const path = this.generatePath(options.buildingId, options.incidentId, options.type, options.subId);
      console.log('Uploading file to path:', path, {
        ...options,
        fileName: file.name
      });

      const fileData = await supabaseStorage.uploadFile(file, { path });
      const publicUrl = await supabaseStorage.getFileUrl(fileData.path);

      return {
        url: publicUrl,
        path: fileData.path,
        size: file.size,
        type: file.type,
        name: file.name
      };
    } catch (error) {
      console.error('Error uploading file:', {
        error,
        path: this.generatePath(options.buildingId, options.incidentId, options.type, options.subId),
        fileName: file.name
      });
      throw error;
    }
  },

  async uploadMultipleFiles(files: File[], options: UploadOptions): Promise<UploadedFile[]> {
    try {
      if (!files || files.length === 0) return [];

      console.log('Uploading multiple files:', {
        ...options,
        fileCount: files.length
      });

      const uploadPromises = files.map(file => this.uploadFile(file, options));
      const results = await Promise.all(uploadPromises);

      return results.filter(result => result !== null);
    } catch (error) {
      console.error('Error uploading incident files:', error);
      throw error;
    }
  },

  async deleteFile(filePath: string): Promise<void> {
    try {
      return await supabaseStorage.deleteFile(filePath);
    } catch (error) {
      console.error('Error deleting incident file:', error);
      throw error;
    }
  },

  async deleteIncidentFiles(buildingId: string, incidentId: string): Promise<void | boolean> {
    try {
      const basePath = this.generatePath(buildingId, incidentId);
      const files = await supabaseStorage.listFiles(basePath);

      if (files && files.length > 0) {
        const filePaths = files.map(file => `${basePath}/${file.name}`);
        return await supabaseStorage.deleteMultipleFiles(filePaths);
      }
      return true;
    } catch (error) {
      console.error('Error deleting incident files:', error);
      throw error;
    }
  },

  async getFileUrl(filePath: string): Promise<string> {
    try {
      return await supabaseStorage.getFileUrl(filePath);
    } catch (error) {
      console.error('Error getting incident file URL:', error);
      throw error;
    }
  }
};
