// src/features/building/hooks/useBuilding.ts

import { useBuildingAnalytics } from './useBuildingAnalytics';
import useGlobalStore from '../../../state/store';

interface UseBuildingReturn {
  buildingId: string | null;
  setBuildingId: (id: string | null) => void;
  apartmentId: string | null;
  setApartmentId: (id: string | null) => void;
  selectApartment: (apartmentId: string) => Promise<void>;
}

export const useBuilding = (): UseBuildingReturn => {
  const buildingId = useGlobalStore((state) => state.buildingId);
  const setBuildingId = useGlobalStore((state) => state.setBuildingId);
  const apartmentId = useGlobalStore((state) => state.apartmentId);
  const setApartmentId = useGlobalStore((state) => state.setApartmentId);

  const { trackApartmentSelected } = useBuildingAnalytics();

  const selectApartment = async (apartmentId: string): Promise<void> => {
    setApartmentId(apartmentId);
    trackApartmentSelected(apartmentId, buildingId!);
  };

  return {
    buildingId,
    setBuildingId,
    apartmentId,
    setApartmentId,
    selectApartment
  };
};