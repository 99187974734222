import { useState, useMemo } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  TextField, Box, useMediaQuery, useTheme
} from '@mui/material';
import { UnfoldMore } from '@mui/icons-material';
import { NoIncidentsMessage } from '.';
import { 
  CATEGORY_LABELS,
  PRIORITY_CONFIG,
  VISIBILITY_LABELS
} from '../../constants';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const IncidentTable = ({ incidents, onIncidentClick }: any) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'title', direction: 'desc' });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSort = (key: any) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const formatDate = (dateString: any) => {
    return format(new Date(dateString), 'PPP', { locale: es });
  };

  const filteredAndSortedIncidents = useMemo(() => {
    return incidents
      .filter((incident: any) =>
        incident.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a: any, b: any) => {
        const direction = sortConfig.direction === 'asc' ? 1 : -1;
        return a[sortConfig.key]?.localeCompare(b[sortConfig.key]) * direction;
      });
  }, [incidents, searchTerm, sortConfig]);

  if (incidents.length === 0) {
    return <NoIncidentsMessage isActiveTab={false} />;
  }

  const tableHeaders = [
    { name: 'Título', key: 'title', width: '30%' },
    { name: 'Fecha de Resolución', key: 'resolution_date', width: '20%' },
    { name: 'Categoría', key: 'category', width: '20%' },
    { name: 'Prioridad', key: 'priority', width: '15%' },
    { name: 'Visibilidad', key: 'visibility', width: '15%' }
  ];

  return (
    <Box>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Buscar por título"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TableContainer component={Paper} sx={{ bgcolor: '#FFFFFF', borderRadius: '8px', overflow: 'hidden' }}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <TableCell 
                  key={header.key}
                  onClick={() => handleSort(header.key)}
                  sx={{ 
                    fontWeight: 'bold', 
                    color: '#001943', 
                    bgcolor: '#E9EDFF',
                    cursor: 'pointer',
                    '&:hover': { bgcolor: '#D8DEFF' },
                    borderBottom: `1px solid #315CAC`,
                    width: header.width,
                    padding: isMobile ? '8px 4px' : '12px 8px',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {header.name}
                    <UnfoldMore sx={{ fontSize: 16, color: sortConfig.key === header.key ? '#001943' : '#A0A0A0' }} />
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAndSortedIncidents.map((incident: any) => (
              <TableRow 
                key={incident.id}
                onClick={() => onIncidentClick(incident.id)}
                sx={{ 
                  cursor: 'pointer',
                  '&:hover': { bgcolor: '#F5F7FF' }
                }}
              >
                <TableCell sx={{ color: '#44464F', padding: isMobile ? '8px 4px' : '12px 8px' }}>{incident.title}</TableCell>
                <TableCell sx={{ color: '#44464F', padding: isMobile ? '8px 4px' : '12px 8px' }}>{formatDate(incident.resolution_date)}</TableCell>
                <TableCell sx={{ color: '#44464F', padding: isMobile ? '8px 4px' : '12px 8px' }}>{CATEGORY_LABELS[incident.category] || incident.category}</TableCell>
                <TableCell sx={{ color: '#44464F', padding: isMobile ? '8px 4px' : '12px 8px' }}>{PRIORITY_CONFIG[incident.priority]?.label || incident.priority}</TableCell>
                <TableCell sx={{ color: '#44464F', padding: isMobile ? '8px 4px' : '12px 8px' }}>{VISIBILITY_LABELS[incident.incident_visibility] || incident.incident_visibility}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default IncidentTable;