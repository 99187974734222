// src/features/incident/components/list/NoIncidentsMessage.tsx

import { Box, Typography } from '@mui/material';
import { Inbox } from 'lucide-react';

const NoIncidentsMessage = ({ isActiveTab = true }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py={4}
    >
      <Inbox size={48} />
      <Typography variant="h6" mt={2}>
        {isActiveTab 
          ? "No hay incidencias activas"
          : "No hay incidencias en el historial"}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {isActiveTab 
          ? "Las incidencias que registres aparecerán aquí"
          : "Las incidencias resueltas hace más de una semana aparecerán aquí"}
      </Typography>
    </Box>
  );
};

export default NoIncidentsMessage;