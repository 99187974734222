// src/features/invitation/components/VisitList.tsx

import { Box } from '@mui/material';
import VisitCard from './VisitCard';
import NoVisitsMessage from './NoVisitsMessage';

const VisitList = ({ visits = [], isActiveTab, onVisitClick }: { visits: any[]; isActiveTab?: boolean; onVisitClick: (visitId: string) => void }) => {
  if (!Array.isArray(visits)) {
    return <NoVisitsMessage isActiveTab={isActiveTab} />;
  }

  return visits.length === 0 ? (
    <NoVisitsMessage isActiveTab={isActiveTab} />
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {visits.map(visit => (
        <VisitCard 
          key={visit.id} 
          visit={visit} 
          onVisitClick={onVisitClick} 
        />
      ))}
    </Box>
  );
};

export default VisitList;