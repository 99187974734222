// src/analytics/index.ts

import { EVENT_TYPES } from './config/events';
import { FEATURE_FLAGS } from './config/featureFlags';
import { GROUP_TYPES } from './config/groups';
import * as posthog from './services/posthog';

// Definir los tipos para los servicios
type AnalyticsService = {
  init: () => void;
  trackEvent: (eventName: string, properties?: Record<string, any>) => void;
  identifyUser: (userId: string, traits?: Record<string, any>) => void;
  resetUser: () => void;
  isFeatureEnabled: (flagKey: string, defaultValue?: boolean) => boolean | undefined;
  getFeatureFlagValue: (flagKey: string) => any;
  setAnalyticsGroup: (groupType: string, groupKey: string, groupProperties?: Record<string, any>) => void;
};

// Este array puede ser expandido para incluir otros servicios en el futuro
const services: AnalyticsService[] = [posthog];

// Inicializa todos los servicios
export const initAnalytics = (): void => {
  services.forEach(service => service.init());
};

// Realiza el seguimiento de la vista de una página
export const trackPageView = (path: string, additionalProperties: Record<string, any> = {}): void => {
  services.forEach(service => service.trackEvent('$pageview', { path, ...additionalProperties }));
};

// Realiza el seguimiento de un evento
export const trackEvent = (eventName: string, properties: Record<string, any> = {}): void => {
  services.forEach(service => service.trackEvent(eventName, properties));
};

// Identifica a un usuario
export const identifyUser = (userId: string, traits: Record<string, any> = {}): void => {
  services.forEach(service => service.identifyUser(userId, traits));
};

// Restablece el usuario
export const resetUser = (): void => {
  services.forEach(service => service.resetUser());
};

// Verifica si una característica está habilitada
export const isFeatureEnabled = (flagKey: string, defaultValue: boolean = false): boolean | undefined => {
  // Seguimos utilizando PostHog para las características por ahora
  return posthog.isFeatureEnabled(flagKey, defaultValue);
};

// Obtiene el valor de una característica
export const getFeatureFlagValue = (flagKey: string): any => {
  // Seguimos utilizando PostHog para las características por ahora
  return posthog.getFeatureFlagValue(flagKey);
};

// Establece el grupo de análisis
export const setAnalyticsGroup = (groupType: string, groupKey: string, groupProperties: Record<string, any> = {}): void => {
  services.forEach(service => service.setAnalyticsGroup(groupType, groupKey, groupProperties));
};

export { EVENT_TYPES, FEATURE_FLAGS, GROUP_TYPES };
