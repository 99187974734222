// src/features/incident/components/create/IncidentForm.tsx

import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  TextField,
  MenuItem,
  Button,
  Stack,
  Typography,
  Alert,
  Chip
} from '@mui/material';
import { useIncident } from '../../hooks/useIncident';
import MediaUpload from './MediaUpload';
import { INCIDENT_CATEGORIES, INCIDENT_VISIBILITY, VISIBILITY_LABELS, VISIBILITY_DESCRIPTIONS } from '../../constants';
import { calculatePriority } from '../../utils/priorityCalculator';

interface FormErrors {
  title?: string;
  description?: string;
  category?: string;
  incident_visibility?: string;
  location_details?: string;
}

type Priority = 'critical' | 'high' | 'medium' | 'low';

const CATEGORY_LABELS = {
  maintenance: 'Mantenimiento',
  security: 'Seguridad',
  noise: 'Ruido',
  cleaning: 'Limpieza',
  common_areas: 'Áreas Comunes',
  elevator: 'Ascensor',
  parking: 'Estacionamiento',
  utilities: 'Servicios',
  other: 'Otros'
};

const PRIORITY_COLORS = {
  critical: 'error',
  high: 'warning',
  medium: 'info',
  low: 'default'
} as const;

const PRIORITY_LABELS = {
  critical: 'Crítica (4h)',
  high: 'Alta (24h)',
  medium: 'Media (72h)',
  low: 'Baja (7 días)',
  null: 'default'
};

const IncidentForm = ({ onSubmit }: { onSubmit: (data: any) => Promise<void> }) => {
  const { loading, error } = useIncident();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    category: '',
    location_details: '',
    incident_visibility: 'community' // Default to community instead of public
  });
  const [priority, setPriority] = useState<Priority>("low");
  const [mediaFiles, setMediaFiles] = useState<File[]>([]);
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  // Calculate priority whenever relevant fields change
  useEffect(() => {
    if (formData.title || formData.description || formData.category)
    {
      const calculatedPriority = calculatePriority(formData);
      setPriority(calculatedPriority.toLowerCase() as Priority);
    }
  }, [formData]); // Changed dependency array to include entire formData object

  const validateForm = useCallback(() => {
    const errors: FormErrors = {};
    if (!formData.title.trim()) errors.title = 'El título es requerido';
    if (!formData.description.trim()) errors.description = 'La descripción es requerida';
    if (!formData.category) errors.category = 'La categoría es requerida';
    if (!formData.incident_visibility) errors.incident_visibility = 'La visibilidad es requerida';
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }, [formData]);

  const handleSubmit = useCallback(async (e: any) => {
    e.preventDefault();
    if (!validateForm()) return;

    try
    {
      await onSubmit({
        ...formData,
        priority,
        media: mediaFiles
      });

      // Reset form
      setFormData({
        title: '',
        description: '',
        category: '',
        location_details: '',
        incident_visibility: INCIDENT_VISIBILITY.PUBLIC // Reset default visibility
      });
      setMediaFiles([]);
      setPriority("low");
    } catch (err)
    {
      console.error('Error submitting form:', err);
    }
  }, [validateForm, formData, mediaFiles, onSubmit, priority]); // Add 'priority' to the dependency array

  const handleInputChange = useCallback((field: keyof FormErrors) => (event: any) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
    // Clear error when field is modified
    if (formErrors[field])
    {
      setFormErrors(prev => ({
        ...prev,
        [field]: null
      }));
    }
  }, [formErrors]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack spacing={3}>
        {error && (
          <Alert severity="error">{error}</Alert>
        )}

        <TextField
          label="Título"
          value={formData.title}
          onChange={handleInputChange('title')}
          error={!!formErrors.title}
          helperText={formErrors.title}
          disabled={loading}
          required
          fullWidth
        />

        <TextField
          select
          label="Categoría"
          value={formData.category}
          onChange={handleInputChange('category')}
          error={!!formErrors.category}
          helperText={formErrors.category}
          disabled={loading}
          required
          fullWidth
        >
          {Object.values(INCIDENT_CATEGORIES).map((value) => (
            <MenuItem key={value} value={value}>
              {CATEGORY_LABELS[value as keyof typeof CATEGORY_LABELS]}
            </MenuItem>
          ))}
        </TextField>

        {priority && (
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="body2" color="text.secondary">
              Prioridad calculada:
            </Typography>
            <Chip
              label={PRIORITY_LABELS[priority]}
              color={PRIORITY_COLORS[priority]}
              size="small"
            />
          </Box>
        )}

        <TextField
          label="Descripción"
          value={formData.description}
          onChange={handleInputChange('description')}
          error={!!formErrors.description}
          helperText={formErrors.description}
          multiline
          rows={4}
          disabled={loading}
          required
          fullWidth
        />

        <TextField
          label="Ubicación"
          value={formData.location_details}
          onChange={handleInputChange('location_details')}
          placeholder="Ej: Piso 3, área de lavandería"
          disabled={loading}
          fullWidth
        />

        <TextField
          select
          label="Visibilidad"
          value={formData.incident_visibility}
          onChange={handleInputChange('incident_visibility')}
          disabled={loading}
          required
          fullWidth
          helperText={VISIBILITY_DESCRIPTIONS[formData.incident_visibility as keyof typeof VISIBILITY_DESCRIPTIONS]}
        >
          {Object.values(INCIDENT_VISIBILITY).map((value) => (
            <MenuItem key={value} value={value}>
              {VISIBILITY_LABELS[value as keyof typeof VISIBILITY_LABELS]}
            </MenuItem>
          ))}
        </TextField>

        <MediaUpload
          files={mediaFiles}
          onChange={setMediaFiles}
          disabled={loading}
          prefix={'mediaFiles'}
        />

        <Button
          type="submit"
          variant="contained"
          disabled={loading}
          fullWidth
        >
          {loading ? 'Enviando...' : 'Enviar Reporte'}
        </Button>
      </Stack>
    </Box>
  );
};

export default IncidentForm;