// src/features/incident/components/list/IncidentList.js
import React, { useState, useMemo, useCallback } from 'react';
import { Stack } from '@mui/material';
import IncidentCard from './IncidentCard';
import IncidentFilters from './IncidentFilters';
import { NoIncidentsMessage } from '.';

const IncidentList = React.memo(({ incidents, onStatusChange, onIncidentClick }: any) => {
  const [filters, setFilters] = useState({
    status: '',
    priority: '',
    category: ''
  });

  const handleFilterChange = useCallback((newFilters: any) => {
    setFilters(newFilters);
  }, []);

  const filteredIncidents = useMemo(() => {
    return incidents.filter((incident: any) => {
      if (filters.status && incident.status !== filters.status) return false;
      if (filters.priority && incident.priority !== filters.priority) return false;
      if (filters.category && incident.category !== filters.category) return false;
      return true;
    });
  }, [incidents, filters]);

  // Define a memoized function to handle incident clicks
  const handleIncidentClickWrapper = useCallback(
    (incidentId: any) => () => {
      onIncidentClick(incidentId);
    },
    [onIncidentClick]
  );

  return (
    <Stack spacing={3}>
      <IncidentFilters
        filters={filters}
        onChange={handleFilterChange}
      />

      {filteredIncidents.length === 0 ? (
        <NoIncidentsMessage isActiveTab={true} />
      ) : (
        <Stack spacing={2}>
          {filteredIncidents.map((incident: any) => (
            <IncidentCard
              key={incident.id}
              incident={incident}
              onStatusChange={onStatusChange}
              onClick={handleIncidentClickWrapper(incident.id)}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
});

export default IncidentList;