// src/features/building/pages/ApartmentSelection.tsx

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  CircularProgress,
  Autocomplete,
  TextField,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import { fetchApartments, claimApartment as claimApartmentService } from '../services/buildingService';
import { useBuilding } from '../hooks/useBuilding';
import { useNavigation } from '../../../navigation/hooks/useNavigation';
import { ROUTES } from '../../../config/routes';
import useGlobalStore from '../../../state/store';

// Define types for Apartment and User
interface Apartment {
  id: string;
  apartment_number: string;
}

const ApartmentSelection: React.FC = () => {
  const user = useGlobalStore((state) => state.user);
  const setUser = useGlobalStore((state) => state.setUser);

  const { buildingId, selectApartment } = useBuilding();
  const { navigateWithParams, getParams } = useNavigation();

  const [apartments, setApartments] = useState<Apartment[]>([]);
  const [selectedApartment, setSelectedApartment] = useState<Apartment | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  useEffect(() => {
    const loadApartments = async () => {
      try {
        const params = getParams();
        const buildingIdToUse = buildingId || params.buildingId;
        if (!buildingIdToUse) {
          throw new Error('Building ID not provided');
        }
        const data = await fetchApartments(buildingIdToUse);
        const sortedApartments = data?.sort((a: Apartment, b: Apartment) => {
          const aNum = parseInt(a.apartment_number.replace(/\D/g, ''), 10);
          const bNum = parseInt(b.apartment_number.replace(/\D/g, ''), 10);
          return aNum - bNum;
        });
        setApartments(sortedApartments!);
      } catch (err: any) {
        console.error('Error loading apartments:', err);
        setSnackbarMessage(`Error loading apartments: ${err.message}`);
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      } finally {
        setLoading(false);
      }
    };

    loadApartments();
  }, [buildingId, getParams]);

  const handleSubmit = async () => {
    if (!selectedApartment || !user) return;

    try {
      setLoading(true);
      console.log('Claiming apartment:', selectedApartment.id, 'for user:', user.id);
      const updatedUser = await claimApartmentService(selectedApartment.id, user);
      console.log('Updated user after claiming apartment:', updatedUser);

      setUser(updatedUser);
      await selectApartment(selectedApartment.id);
      setSnackbarMessage('Apartamento seleccionado exitosamente');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      navigateWithParams(ROUTES.DASHBOARD);
    } catch (err: any) {
      console.error('Error claiming apartment:', err);
      setSnackbarMessage(`Error al seleccionar el apartamento: ${err.message}`);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <CircularProgress />;

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Selecciona Tu Departamento
      </Typography>
      <Autocomplete
        options={apartments}
        getOptionLabel={(option: Apartment) => option.apartment_number}
        renderInput={(params) => <TextField {...params} label="Apartamento" />}
        onChange={(event, newValue: Apartment | null) => {
          setSelectedApartment(newValue);
        }}
        fullWidth
        sx={{ mb: 2 }}
      />
      <Button
        onClick={handleSubmit}
        variant="contained"
        fullWidth
        disabled={!selectedApartment || loading}
      >
        {loading ? 'Procesando...' : 'Confirmar Selección'}
      </Button>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ApartmentSelection;
