// src/features/dashboard/pages/Dashboard.tsx

import { useEffect } from 'react';
import { Box, Container, CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';
import VisitMain from '../../invitation/pages/VisitMain';
import DashboardWelcomeCard from '../components/DashboardWelcomeCard';
import { useDashboardAnalytics } from '../hooks/useDashboardAnalytics';
import { useNavigation } from '../../../navigation/hooks/useNavigation';
import { ROUTES } from '../../../config/routes';
import useGlobalStore from '../../../state/store';

const Dashboard = () => {
  const { trackDashboardViewed } = useDashboardAnalytics();
  
  const user = useGlobalStore((state) => state.user);
  const loading = useGlobalStore((state) => state.loading);

  const { clearUrlParams } = useNavigation();
  const { pathname } = useLocation();

  useEffect(() => {
    if (user && !loading) {
      trackDashboardViewed(user.id);
      clearUrlParams();
    }
  }, [user, loading, trackDashboardViewed, clearUrlParams]);

  const showWelcomeCard = pathname === ROUTES.DASHBOARD || pathname === '/';

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box 
        sx={{ 
          my: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        }}
      >
        {showWelcomeCard && <DashboardWelcomeCard user={user} />}
        <VisitMain />
      </Box>
    </Container>
  );
};

export default Dashboard;