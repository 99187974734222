// src/features/dashboard/components/DashboardWelcomeCard.tsx

import React from 'react';
import { Card, CardContent, Typography, Box, Skeleton, useTheme, Button } from '@mui/material';
import { Apartment, WhatsApp } from '@mui/icons-material';

const DashboardWelcomeCard = ({ user }: { user: any }): JSX.Element => {
  const theme = useTheme();

  if (!user) {
    return (
      <Card sx={{ mb: theme.spacing(2.5) }}>
        <CardContent>
          <Skeleton variant="rectangular" width="100%" height={70} />
        </CardContent>
      </Card>
    );
  }

  const isResident = user.roles && Array.isArray(user.roles) && user.roles.includes('resident');

  const whatsappNumber = import.meta.env.VITE_WHATSAPP_NUMBER;
  const whatsappMessage = encodeURIComponent('Hola, quiero más información de Domus para mi edificio');
  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${whatsappMessage}`;

  return (
    <Card sx={{ mb: theme.spacing(2.5) }}>
      <CardContent sx={{ py: theme.spacing(1.5), px: theme.spacing(2) }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <Apartment sx={{ fontSize: 40, color: theme.palette.primary.main, mb: theme.spacing(1) }} />
          <Typography variant="h6" component="div" sx={{ fontSize: '1.2rem', mb: 1 }}>
            ¡Bienvenido a Domus, {user.first_name || ''}!
          </Typography>
          {isResident ? (
            <Typography variant="body2" color="text.secondary">
              Aquí encontrarás todas las funciones para residentes. Explora y disfruta de los beneficios de Domus.
            </Typography>
          ) : (
            <>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5 }}>
                Tu edificio aún no está en Domus. Contacta a Domus para agregar tu edificio y disfrutar de todos los beneficios.
              </Typography>
              <ContactButton whatsappLink={whatsappLink} />
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

const ContactButton = ({ whatsappLink }: { whatsappLink: string }) => (
  <Button
    variant="contained"
    startIcon={<WhatsApp />}
    href={whatsappLink}
    target="_blank"
    rel="noopener noreferrer"
    sx={{
      bgcolor: '#25D366',
      color: 'white',
      '&:hover': {
        bgcolor: '#128C7E',
      },
      width: '248px',
      height: '38px',
      padding: '2px 12px',
      minHeight: 0,
      minWidth: 0,
      lineHeight: 1,
      fontSize: '0.85rem',
      '& .MuiButton-startIcon': {
        margin: 0,
        marginRight: '4px',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1rem',
      },
      textTransform: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    Contactar a Domus
  </Button>
);

export default React.memo(DashboardWelcomeCard);