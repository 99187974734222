// src/features/auth/components/PhoneInput.tsx

import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { Box } from '@mui/material';

// Definir los tipos para las props del componente
interface PhoneInputComponentProps {
  value: string;
  onChange: (phone: string) => void;
}

const PhoneInputComponent: React.FC<PhoneInputComponentProps> = ({ value, onChange }) => {
  return (
    <Box
      sx={{
        '& .react-tel-input': {
          marginTop: '16px',
        },
        '& .react-tel-input .special-label': {
          display: 'none', // Hide the "Phone" label
        },
        '& .react-tel-input .form-control': {
          width: '100%',
          height: '56px',
          fontSize: '1rem',
          borderRadius: '16px',
          backgroundColor: 'white',
          border: '1px solid rgba(0, 0, 0, 0.23)',
          '&:hover': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          '&:focus': {
            borderColor: 'primary.main',
            borderWidth: '2px',
            boxShadow: 'none',
          },
        },
        '& .react-tel-input .flag-dropdown': {
          backgroundColor: 'transparent',
          border: 'none',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        '& .react-tel-input .selected-flag': {
          backgroundColor: 'transparent',
          '&:hover, &:focus': {
            backgroundColor: 'transparent',
          },
        },
        '& .react-tel-input .country-list': {
          margin: '8px 0 0 -1px',
        },
      }}
    >
      <PhoneInput
        country={'pe'}
        value={value}
        onChange={phone => onChange(phone)}
        inputProps={{
          required: true,
          placeholder: 'Teléfono',
        }}
        inputStyle={{
          width: '100%',
          height: '56px',
          fontSize: '1rem',
          borderRadius: '16px',
          backgroundColor: 'white',
          border: '1px solid rgba(0, 0, 0, 0.23)',
        }}
        buttonStyle={{
          backgroundColor: 'transparent',
          border: 'none',
          borderTopLeftRadius: '16px',
          borderBottomLeftRadius: '16px',
        }}
        disableCountryCode={false}
        countryCodeEditable={false}
        enableSearch={false}
        disableSearchIcon={true}
      />
    </Box>
  );
};

export default PhoneInputComponent;
