// src/config/routes.ts

type RouteParams = {
  inviteToken?: string;
  visitId?: string;
  buildingId?: string;
  id?: string;
};

export const ROUTES = {
  // Authentication routes
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',

  // Dashboard route
  DASHBOARD: '/visits',

  // Invitation route
  INVITATION: '/invite/:inviteToken',

  // Visit routes
  VISIT_DETAIL: '/visits/:visitId',

  // Building routes
  BUILDING_LANDING: '/building/:buildingId',
  APARTMENT_SELECTION: '/apartment-selection',

  // Incident routes
  INCIDENTS: '/incidents',
  INCIDENT_NEW: '/incidents/new',
  INCIDENT_DETAIL: '/incidents/:id',

  // External links
  TERMS: 'https://appdomus.io/terminos-y-condiciones',
  PRIVACY: 'https://appdomus.io/privacidad'
} as const;

// Tipo que representa las rutas con parámetros
export type RoutePaths = {
  [key in keyof typeof ROUTES]: string;
};

export type RouteParamsType = RouteParams;
