// src/features/invitation/hooks/useInvitationAnalytics.ts

import { useCallback } from 'react';
import { useAnalytics } from '../../../analytics/hooks/useAnalytics';
import { EVENT_TYPES } from '../../../analytics/config/events';

export const useInvitationAnalytics = () => {
  const { track } = useAnalytics();

  const trackInvitationViewed = useCallback((invitationId: string, userId: string) => {
    track(EVENT_TYPES.INVITATION_VIEWED, { invitationId, userId });
  }, [track]);

  const trackVisitCreated = useCallback((invitationId: string, userId: string) => {
    track(EVENT_TYPES.VISIT_CREATED, { invitationId, userId });
  }, [track]);

  const trackVisitDetailViewed = useCallback((visitId: string, userId: string) => {
    track(EVENT_TYPES.VISIT_DETAIL_VIEWED, { visitId, userId });
  }, [track]);

  return {
    trackInvitationViewed,
    trackVisitCreated,
    trackVisitDetailViewed,
  };
};