// src/analytics/config/events.ts

export enum EVENT_TYPES {
  // User Authentication
  LOGIN = 'user_login',
  LOGIN_FROM_INVITATION = 'user_login_from_invitation',
  LOGIN_FROM_BUILDING = 'user_login_from_building',
  LOGOUT = 'user_logout',
  REGISTER = 'user_register',
  REGISTER_FROM_INVITATION = 'user_register_from_invitation',
  REGISTER_FROM_BUILDING = 'user_register_from_building',
  PASSWORD_RESET_REQUESTED = 'password_reset_requested',
  PASSWORD_RESET_COMPLETED = 'password_reset_completed',

  // User Actions
  PROFILE_UPDATED = 'profile_updated',
  PROFILE_VIEWED = 'profile_viewed',

  // Invitation Flow
  INVITATION_VIEWED = 'invitation_viewed',
  INVITATION_ACCEPTED = 'invitation_accepted',
  INVITATION_REJECTED = 'invitation_rejected',
  INVITATION_SENT = 'invitation_sent',

  // Visit Management
  VISIT_CREATED = 'visit_created',
  VISIT_UPDATED = 'visit_updated',
  VISIT_CANCELLED = 'visit_cancelled',
  VISIT_COMPLETED = 'visit_completed',

  // Building and Apartment
  BUILDING_VIEWED = 'building_viewed',
  APARTMENT_SELECTED = 'apartment_selected',
  APARTMENT_CLAIMED = 'apartment_claimed',

  // Dashboard
  DASHBOARD_VIEWED = 'dashboard_viewed',

  // Payments
  PAYMENT_INITIATED = 'payment_initiated',
  PAYMENT_COMPLETED = 'payment_completed',
  PAYMENT_FAILED = 'payment_failed',

  // System Events
  ERROR_OCCURRED = 'error_occurred',
  FEATURE_FLAG_CHECKED = 'feature_flag_checked',

  // Navigation
  PAGE_VIEW = 'page_view',

  // Add Incident Events
  INCIDENT_CREATED = 'incident_created',
  INCIDENT_UPDATED = 'incident_updated',
  INCIDENT_STATUS_CHANGED = 'incident_status_changed',
  INCIDENT_COMMENT_ADDED = 'incident_comment_added',
  INCIDENT_ASSIGNED = 'incident_assigned',
  INCIDENT_VIEWED = 'incident_viewed',
  INCIDENT_MEDIA_UPLOADED = 'incident_media_uploaded',

  // Add more event types as needed
  DASHBOARD_WIDGET_INTERACTION = 'dashboard_widget_interaction',
  INCIDENT_RATED = 'incident_rated',
  VISIT_DETAIL_VIEWED = 'visit_detail_viewed',
  REGISTER_EMAIL_SUBMITTED = 'register_email_submitted',
  REGISTER_ERROR = 'register_error',
  REGISTER_EMAIL_CONFIRMED = 'register_email_confirmed',
  REGISTER_COMPLETED = 'register_completed',
}
