// src/features/building/services/buildingService.js

import { secureApi } from '../../../supabase/secureApi';
import { authTokenService } from '../../../features/auth/services/authTokenService';
import { publicApi } from '../../../supabase/publicApi';

export const fetchBuildingDetails = async (buildingId: any) => {
  try
  {
    // Check if user is authenticated
    const isAuthenticated = !!authTokenService.getSession();

    // Try fetching via public route first
    let building = await publicApi.get('buildings', {
      select: 'id, name, address',
      filter: { id: buildingId },
      single: true
    });

    // If authenticated and no public data, try secure route
    if (!building && isAuthenticated)
    {
      building = await secureApi.get('buildings', {
        select: '*',
        filter: { id: buildingId },
        single: true
      });
    }

    // If still no data, try fetching via invitation
    if (!building)
    {
      building = await publicApi.get('buildings', {
        select: 'id, name, address, apartments!inner(id, invitations!inner(invitation_token))',
        filter: { id: buildingId },
        single: true
      });
    }

    if (!building)
    {
      throw new Error('Building not found or access denied');
    }

    return building;
  } catch (error: any)
  {
    console.error('Failed to fetch building details:', error);
    if (error.code === 'PGRST116' || error.status === 406)
    {
      return null;
    }
    throw new Error('Failed to fetch building details');
  }
};

export const fetchApartments = async (buildingId: any) => {
  try
  {
    return await secureApi.get('apartments', {
      filter: { building_id: buildingId }
    });
  } catch (error)
  {
    console.error('Failed to fetch apartments:', error);
    throw new Error('Failed to fetch apartments');
  }
};

export const claimApartment = async (apartmentId: any, user: any) => {
  try
  {
    console.log('Starting apartment claim process...');

    console.log('Creating new claim...');
    await secureApi.post('resident_apartments', {
      resident_id: user.id,
      apartment_id: apartmentId,
      is_active: true
    });

    console.log('Fetching new claim...');
    const newClaim = await secureApi.get('resident_apartments', {
      filter: {
        resident_id: user.id,
        apartment_id: apartmentId,
        is_active: true
      },
      single: true
    });
    console.log('New claim:', newClaim);

    if (!newClaim)
    {
      throw new Error('Failed to fetch resident_apartments entry');
    }

    console.log('Fetching apartment data...');
    const apartmentData = await secureApi.get('apartments', {
      select: 'apartment_number, buildings(name)',
      filter: { id: apartmentId },
      single: true
    });
    console.log('Apartment data:', apartmentData);

    if (!apartmentData)
    {
      throw new Error('Apartment not found');
    }

    const currentRoles = user.roles || [];
    const updatedRoles = currentRoles.includes('resident')
      ? currentRoles
      : [...currentRoles, 'resident'];

    console.log('Updating user profile...');
    await secureApi.update('users', user.id, {
      apartment: [apartmentData.apartment_number],
      building: [apartmentData.buildings.name],
      roles: updatedRoles
    });

    // Fetch the updated user profile
    console.log('Fetching updated user profile...');
    const updatedUser = await secureApi.get('users', {
      filter: { id: user.id },
      single: true
    });
    console.log('Updated user:', updatedUser);

    if (!updatedUser)
    {
      console.warn('Failed to fetch updated user profile, returning original user with updates');
      return {
        ...user,
        apartment: [apartmentData.apartment_number],
        building: [apartmentData.buildings.name],
        roles: updatedRoles
      };
    }

    return updatedUser;
  } catch (error)
  {
    console.error('Error claiming apartment:', error);
    throw error;
  }
};

export const registerUser = async (userData: any) => {
  try
  {
    const { data, error } = await secureApi.rpc('signup', {
      p_email: userData.email,
      p_password: userData.password,
      p_first_name: userData.firstName,
      p_last_name: userData.lastName,
      p_building_id: userData.buildingId,
      p_user_type: 'resident'
    });

    if (error) throw error;
    return data;
  } catch (error)
  {
    console.error('Error registering user:', error);
    throw new Error('Failed to register user');
  }
};
