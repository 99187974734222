// src/features/invitation/components/StatusChip.tsx

import { Chip, styled } from '@mui/material';

// Definir un tipo para los posibles valores de 'status'
type Status = 'pending' | 'checked_in' | 'cancelled' | string;

// Tipificación de las props de StatusChip
interface StatusChipProps {
  status: Status;
}

// Estilizado del componente Chip con base en el status
const StyledChip = styled(Chip)<{ status: Status }>(({ theme, status }) => ({
  fontWeight: 'bold',
  fontSize: '0.75rem',
  ...(status === 'pending' && {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
  }),
  ...(status === 'checked_in' && {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
  }),
  ...(status === 'cancelled' && {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
  }),
}));

// Componente StatusChip que muestra el Chip con el estado correspondiente
const StatusChip = ({ status }: StatusChipProps) => {
  const getLabel = (status: Status): string => {
    switch (status) {
      case 'pending':
        return 'Pendiente';
      case 'checked_in':
        return 'Registrado';
      case 'cancelled':
        return 'Cancelado';
      default:
        return status;
    }
  };

  return (
    <StyledChip
      label={getLabel(status)}
      status={status}
      size="small"
    />
  );
};

export default StatusChip;
