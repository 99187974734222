// src/shared/layouts/AuthLayout.tsx

import React from 'react';
import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useAnalytics } from '../../analytics/hooks/useAnalytics';
import PageTransition from '../components/PageTransition';

const AuthLayout = () => {
  const { trackError } = useAnalytics();

  React.useEffect(() => {
    const handleError = (event: any) => {
      trackError(event.error.message, event.error.stack);
    };
    window.addEventListener('error', handleError);
    return () => window.removeEventListener('error', handleError);
  }, [trackError]);

  return (
    <PageTransition>
    <Container component="main" sx={{ flexGrow: 1, py: 4, maxWidth: 400, margin: 'auto' }}>
      <Box sx={{ maxWidth: 300, margin: 'auto', p: 2 }}>
        <img 
          src={'./images/domus.png'}
          alt="Domus"
          style={{ width: '100%', marginBottom: '8px' }}
        />
      </Box>
      <Outlet />
    </Container>
    </PageTransition>
  );
};

export default AuthLayout;