// src/supabase/publicApi.ts

import { supabase } from './client';

export const publicApi = {
  async get(table: string, options: any = {}) {
    try
    {
      console.log(`PublicApi GET request to ${table}:`, options);

      let query = supabase.from(table).select(options.select || '*');

      if (options.filter)
      {
        Object.entries(options.filter).forEach(([key, value]) => {
          query = query.eq(key, value);
        });
      }

      if (options.order)
      {
        Object.entries(options.order).forEach(([key, direction]) => {
          query = query.order(key, { ascending: direction === 'asc' });
        });
      }

      // Get the raw query for debugging
      const { data: debugData, error: debugError } = await query;
      console.log(`Raw query result for ${table}:`, { data: debugData, error: debugError });

      if (options.single)
      {
        const { data, error } = await query.single();

        if (error)
        {
          // Handle "no rows returned" error gracefully
          if (error.code === 'PGRST116')
          {
            console.log(`No rows found in ${table} for filter:`, options.filter);
            return null;
          }
          throw error;
        }

        return data;
      } else
      {
        const { data, error } = await query;

        if (error) throw error;
        return data;
      }
    } catch (error: any)
    {
      console.error(`Public API error for ${table}:`, {
        options,
        errorCode: error.code,
        errorMessage: error.message,
        errorDetails: error.details
      });

      // For invitation token not found, return null instead of throwing
      if (error.code === 'PGRST116' || error.status === 406)
      {
        return null;
      }

      throw error;
    }
  }
};