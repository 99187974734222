// src/features/auth/services/authTokenService.js
import { supabase } from '../../../supabase/client';
import { realtimeService } from '../../../supabase/services/realtimeService';
import useGlobalStore from '../../../state/store';

class AuthTokenService {
  private currentSession: any = null;
  private refreshTimer: NodeJS.Timeout | null = null;
  private readonly subscribers: Set<(session: any) => void> = new Set();
  private readonly REFRESH_MARGIN: number = 5 * 60 * 1000; // 5 minutes
  private isInitialized: boolean = false;
  private readonly realtimeSubscriptions: Set<any> = new Set();
  private isPasswordUpdateInProgress: boolean = false;

  constructor() {
    this.currentSession = null;
    this.refreshTimer = null;
    this.subscribers = new Set();
    this.REFRESH_MARGIN = 5 * 60 * 1000; // 5 minutes
    this.isInitialized = false;
    this.realtimeSubscriptions = new Set();
    this.isPasswordUpdateInProgress = false;
  }

  async init() {
    if (this.isInitialized) return;

    try
    {
      const { data: { session } } = await supabase.auth.getSession();
      if (session)
      {
        await this.setSession(session);
      }

      // Setup auth state listener
      const { data: { subscription } } = supabase.auth.onAuthStateChange(
        async (event, session) => {
          console.log('Auth state change:', event, session?.user?.id);

          if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED')
          {
            await this.setSession(session);
            // Fetch user profile after setting session
            await useGlobalStore.getState().fetchUserProfile(session?.user);
          } else if (event === 'SIGNED_OUT' || event === 'USER_UPDATED')
          {
            if (!this.isPasswordUpdateInProgress)
            {
              this.clearSession();
              // Clear user in global store
              useGlobalStore.getState().setUser(null);
            }
          }
          // Handle INITIAL_SESSION event
          else if (event === 'INITIAL_SESSION' && session)
          {
            await this.setSession(session);
          }
        }
      );

      this.realtimeSubscriptions.add(subscription);
      this.isInitialized = true;
      return session;
    } catch (error)
    {
      console.error('Auth initialization error:', error);
      throw error;
    }
  }

  async setSession(session: any) {
    try
    {
      if (!session?.access_token)
      {
        throw new Error('Invalid session data');
      }

      // Validate token before setting
      const isValid = await this.validateToken(session.access_token);
      if (!isValid)
      {
        throw new Error('Invalid token');
      }

      this.currentSession = session;
      this.setupAutoRefresh();

      // Connect realtime service
      await realtimeService.connect();

      this.notifySubscribers(session);
      return session;
    } catch (error)
    {
      console.error('Error setting session:', error);
      if (!this.isPasswordUpdateInProgress)
      {
        this.clearSession();
      }
      throw error;
    }
  }

  async reconnectRealtimeClient() {
    if (!this.currentSession?.access_token) return;

    try
    {
      // Remove existing subscriptions
      for (const subscription of this.realtimeSubscriptions)
      {
        if (subscription?.unsubscribe)
        {
          await subscription.unsubscribe();
        }
      }
      this.realtimeSubscriptions.clear();

      // Reconnect the realtime client
      await supabase.removeAllChannels();
      const { timeout } = await supabase.channel('system').subscribe();
      if (timeout) throw timeout;

    } catch (error)
    {
      console.error('Error reconnecting realtime client:', error);
    }
  }

  clearSession() {
    this.currentSession = null;
    if (this.refreshTimer)
    {
      clearInterval(this.refreshTimer);
      this.refreshTimer = null;
    }

    // Disconnect realtime service
    realtimeService.disconnect();

    // Clean up all subscriptions
    this.realtimeSubscriptions.forEach(subscription => {
      if (subscription?.unsubscribe)
      {
        subscription.unsubscribe();
      }
    });
    this.realtimeSubscriptions.clear();

    this.notifySubscribers(null);
  }

  setupAutoRefresh() {
    if (this.refreshTimer)
    {
      clearInterval(this.refreshTimer);
    }

    this.refreshTimer = setInterval(async () => {
      if (!this.currentSession) return;

      const expiresAt = new Date(this.currentSession.expires_at).getTime();
      const now = new Date().getTime();

      if (expiresAt - now <= this.REFRESH_MARGIN)
      {
        await this.refreshSession();
      }
    }, 60000);
  }

  async refreshSession() {
    try
    {
      const { data: { session }, error } = await supabase.auth.refreshSession();
      if (error) throw error;
      this.setSession(session);
      return session;
    } catch (error)
    {
      console.error('Session refresh failed:', error);
      this.clearSession();
      throw error;
    }
  }

  // TODO: Fix this supabase functions. These doesnt exists.
  // async reconnectRealtimeSubscriptions() {
  //   if (this.currentSession?.access_token)
  //   {
  //     await supabase.removeAllSubscriptions();
  //     await supabase.reconnect();
  //   }
  // }

  async validateToken(token: any) {
    try
    {
      const { data: { user }, error } = await supabase.auth.getUser(token);
      if (error)
      {
        console.error('Token validation error:', error);
        return false;
      }
      return !!user;
    } catch (error)
    {
      console.error('Token validation failed:', error);
      return false;
    }
  }

  getSession() {
    return this.currentSession;
  }

  getCurrentSession() {
    return this.getSession();
  }

  isAuthenticated() {
    return !!this.currentSession?.access_token;
  }

  getAccessToken() {
    return this.currentSession?.access_token;
  }

  subscribe(callback: any) {
    this.subscribers.add(callback);
    return () => this.subscribers.delete(callback);
  }

  notifySubscribers(session: any) {
    this.subscribers.forEach(callback => callback(session));
  }

  // Add retry mechanism for failed requests
  async retryOperation(operation: any, maxRetries = 3, delayMs = 1000) {
    let lastError;

    for (let i = 0; i < maxRetries; i++)
    {
      try
      {
        return await operation();
      } catch (error: any)
      {
        lastError = error;
        console.warn(`Operation failed (attempt ${i + 1}/${maxRetries}):`, error);

        if (error.message?.includes('Load failed'))
        {
          await new Promise(resolve => setTimeout(resolve, delayMs * (i + 1)));
          continue;
        }
        throw error;
      }
    }

    throw lastError;
  }

  // Add cleanup method
  async cleanup() {
    this.clearSession();
    this.isInitialized = false;
  }
}

export const authTokenService = new AuthTokenService();