// src/features/auth/components/UserInfoInput.tsx

import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import PhoneInputComponent from './PhoneInput';

// Definir los tipos para las props del componente
interface UserInfoInputProps {
  onSubmit: (data: { firstName: string; lastName: string; dni: string; phone: string | null}) => void;
  initialValues?: {
    firstName?: string;
    lastName?: string;
    dni?: string;
    phone?: string | null;
  };
}

const UserInfoInput: React.FC<UserInfoInputProps> = ({ onSubmit, initialValues = {} }) => {
  const [firstName, setFirstName] = useState<string>(initialValues.firstName || '');
  const [lastName, setLastName] = useState<string>(initialValues.lastName || '');
  const [dni, setDni] = useState<string>(initialValues.dni || '');
  const [phone, setPhone] = useState<string>(initialValues.phone || '');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({ 
      firstName, 
      lastName, 
      dni, 
      phone: phone || null,
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h6" gutterBottom>
        Datos Básicos
      </Typography>
      <TextField
        required
        label="Nombre"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        required
        label="Apellidos"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        required
        label="DNI"
        value={dni}
        onChange={(e) => setDni(e.target.value)}
        fullWidth
        margin="normal"
      />
      <PhoneInputComponent
        value={phone}
        onChange={setPhone}
      />
      <Button 
        type="submit" 
        fullWidth 
        variant="contained" 
        sx={{ mt: 2, borderRadius: '55px', height: '56px' }}
      >
        Crear cuenta
      </Button>
    </Box>
  );
};

export default UserInfoInput;
