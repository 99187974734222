// src/features/incident/components/shared/PriorityBadge.tsx

import { Chip } from '@mui/material';
import { 
  ArrowDown, 
  ArrowRight, 
  ArrowUp,
  AlertTriangle 
} from 'lucide-react';

const PRIORITY_CONFIG: { [key: string]: any } = {
  low: {
    label: 'Baja',
    icon: ArrowDown,
    color: 'default',
    bgColor: '#f5f5f5'
  },
  medium: {
    label: 'Media',
    icon: ArrowRight,
    color: 'info',
    bgColor: '#e3f2fd'
  },
  high: {
    label: 'Alta',
    icon: ArrowUp,
    color: 'warning',
    bgColor: '#fff3e0'
  },
  critical: {
    label: 'Crítica',
    icon: AlertTriangle,
    color: 'error',
    bgColor: '#ffebee'
  }
};

const PriorityBadge = ({ priority, size = 'small' }: { priority: string; size?: 'small' | 'medium'}) => {
  const config = PRIORITY_CONFIG[priority] || PRIORITY_CONFIG.medium;
  const IconComponent = config.icon;

  return (
    <Chip
      icon={<IconComponent size={size === 'small' ? 14 : 16} />}
      label={config.label}
      color={config.color}
      size={size}
      sx={{
        backgroundColor: config.bgColor,
        '& .MuiChip-icon': {
          marginLeft: '8px'
        }
      }}
    />
  );
};

export default PriorityBadge;