// src/features/auth/services/authService.js

import { supabase } from '../../../supabase/client';
import { authTokenService } from './authTokenService';

export const authService = {
  async signIn(email: string, password: string) {
    try
    {
      console.log('Attempting to sign in with email:', email);
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password
      });
      if (error) throw error;
      console.log('Sign in successful, user data:', data.user);
      return data.user;
    } catch (error)
    {
      console.error('Sign in error:', error);
      throw error;
    }
  },

  async signOut() {
    try
    {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
    } catch (error)
    {
      console.error('Sign out error:', error);
      throw error;
    }
  },

  async signUpWithOtp(email: string, password: string) {
    try
    {
      console.log('Attempting to sign up user with email:', email);
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            document_number: 'PENDING',
            is_active: true,
          }
        }
      });
      if (error) throw error;
      console.log('Signup successful. User data:', data);
      return data;
    } catch (error)
    {
      console.error('Unexpected error during signUp:', error);
      throw error;
    }
  },

  async verifyOtp(email: string, token: string) {
    try
    {
      console.log('Verifying OTP for email:', email);
      const { data, error } = await supabase.auth.verifyOtp({
        email,
        token,
        type: 'signup'
      });
      if (error) throw error;

      console.log('Verification response:', data);

      const { user, session } = data;

      if (!session)
      {
        throw new Error('No session returned after OTP verification');
      }

      console.log('Obtained session after OTP verification:', session);

      // Create initial user profile if we have a user
      if (user)
      {
        await this.updateUserProfile(user.id, {
          email: user.email,
          auth_user_id: user.id,
          is_active: true,
          roles: ['visitor']
        });
      }

      // Initialize auth token service with session
      await authTokenService.setSession(session);

      console.log('OTP verified successfully for:', email);
      return { user, session };
    } catch (error)
    {
      console.error('Verify OTP error:', error);
      throw error;
    }
  },

  async getUserProfile(userId: string) {
    try
    {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('auth_user_id', userId)
        .maybeSingle();

      if (error && error.code !== 'PGRST116') throw error; // Allow 'No rows found' error

      return data || null; // Return null if no data
    } catch (error)
    {
      console.error('Get user profile error:', error);
      throw error;
    }
  },

  async updateUserProfile(userId: any, userData: any) {
    try
    {
      console.log('Upserting user profile for userId:', userId);

      const profileData = {
        auth_user_id: userId,
        document_number: userData.dni || null,
        document_type: userData.documentType || 'dni',
        first_name: userData.firstName,
        last_name: userData.lastName || null,
        phone: userData.phone || null,
        email: userData.email,
        is_active: true,
        roles: ['visitor']
      };

      // First try to get existing user
      const { data: existingUser } = await supabase
        .from('users')
        .select('id')
        .eq('auth_user_id', userId)
        .maybeSingle();

      let result;
      if (existingUser)
      {
        // Update
        const { data, error } = await supabase
          .from('users')
          .update(profileData)
          .eq('auth_user_id', userId)
          .select()
          .single();

        if (error) throw error;
        result = data;
      } else
      {
        // Insert
        const { data, error } = await supabase
          .from('users')
          .insert(profileData)
          .select()
          .single();

        if (error) throw error;
        result = data;
      }

      if (!result) throw new Error('Failed to create/update user profile');
      return result;

    } catch (error)
    {
      console.error('Error upserting user profile:', error);
      throw error;
    }
  },

  async updateUserPassword(password: string) {
    try {
      console.log('Starting password update process');
      const { data: { session } } = await supabase.auth.getSession();
      console.log('Current session XD:', session);

      if (!session) {
        throw new Error('No active session found');
      }

      // Adjust the updateUser call to use proper parameters
      const { data, error } = await supabase.auth.updateUser({ 
        password: password,
      });

      if (error) {
        console.error('Password update error:', error);
        throw error;
      }

      if (!data?.user) {
        console.error('No user returned from password update');
        throw new Error('Password update failed - no user returned');
      }

      console.log('Password update successful for user:', data.user.id);
      return data.user;
    } catch (error) {
      console.error('Error in updateUserPassword:', error);
      throw error;
    }
  },

  async getUserResidenceStructure(userId: string) {
    try
    {
      // First get resident_apartments directly
      const { data: residentData } = await supabase
        .from('resident_apartments')
        .select('apartment_id')
        .eq('resident_id', userId)
        .eq('is_active', true);

      if (!residentData?.length) return [];

      // Then get apartments info
      const apartmentIds = residentData.map(ra => ra.apartment_id);
      const { data: apartmentsData } = await supabase
        .from('apartments')
        .select(`
          id,
          apartment_number,
          building_id
        `)
        .in('id', apartmentIds);

      if (!apartmentsData?.length) return [];

      // Finally get buildings info
      const buildingIds = [...new Set(apartmentsData.map(a => a.building_id))];
      const { data: buildingsData } = await supabase
        .from('buildings')
        .select(`
          id,
          name,
          address
        `)
        .in('id', buildingIds);

      // Structure the data
      const buildings = buildingsData?.map(building => ({
        id: building.id,
        name: building.name,
        address: building.address,
        apartments: apartmentsData
          .filter(apt => apt.building_id === building.id)
          .map(apt => ({
            id: apt.id,
            apartment_number: apt.apartment_number
          }))
      }));

      console.log('Structured building data:', buildings);
      return buildings;
    } catch (error)
    {
      console.error('Error fetching user residence structure:', error);
      throw error;
    }
  },

  // TODO: Improve method, made with AI
  async resetPassword(email: string) {
    try {
      console.log('Attempting to reset password for email:', email);

      // Supabase's built-in method to send a password reset email
      const { data, error } = await supabase.auth.resetPasswordForEmail(email);

      if (error) {
        console.error('Password reset error:', error);
        throw error;
      }

      console.log('Password reset email sent successfully to:', email);
      return data;
    } catch (error) {
      console.error('Error in resetPassword:', error);
      throw error;
    }
  }
};