// src/features/auth/hooks/useAuth.ts

import { useCallback } from 'react';
import { useAuthAnalytics } from './useAuthAnalytics';
import { useNavigation } from '../../../navigation/hooks/useNavigation';
import { invitationService } from '../../invitation/services/invitationService';
import { ROUTES } from '../../../config/routes';
import { enhancedAuthService } from '../services/enhancedAuthService';
import { authService } from '../services/authService';
import { authTokenService } from '../services/authTokenService';
import useGlobalStore from '../../../state/store';

// Tipos para el estado global y los parámetros de usuario
interface User {
  id: string;
  email: string;
  [key: string]: any;
}

// Definir los tipos para las funciones
interface UseAuth {
  user: User | null;
  loading: boolean;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
  signUpWithOtp: (email: string, password: string) => Promise<any>;
  verifyOtp: (email: string, token: string) => Promise<any>;
  updateUserPassword: (password: string) => Promise<any>;
  updateUserProfile: (userId: string, userData: any) => Promise<any>;
  completeRegistration: (email: string, userInfo: any) => Promise<User>;
  setUser: (user: User | null) => void;
}

export const useAuth = (): UseAuth => {
  const user = useGlobalStore((state) => state.user);
  const loading = useGlobalStore((state) => state.loading);
  const setUser = useGlobalStore((state) => state.setUser);
  const { trackLogin, trackLogout, trackRegister } = useAuthAnalytics();
  const { navigateWithParams, clearParams, getParams } = useNavigation();

  const login = useCallback(async (email: string, password: string): Promise<boolean> => {
    try
    {
      console.log('Starting login process for email:', email);
      const { data, error } = await enhancedAuthService.signInWithPassword({
        email,
        password
      });

      if (error) throw error;

      console.log('Login successful. Auth user data:', data?.user);
      const fullUser: any = data!.user;
      setUser(fullUser);
      trackLogin(fullUser.id, email);

      const { invitationToken, buildingId } = getParams();
      console.log('Params:', { invitationToken, buildingId });

      if (invitationToken)
      {
        try
        {
          console.log('Processing invitation');
          await invitationService.createVisitFromInvitation(invitationToken, fullUser.id);
          console.log('Invitation processed');
        } catch (inviteError)
        {
          console.error('Error processing invitation:', inviteError);
        }
      }

      console.log('Preparing navigation');
      if (buildingId)
      {
        console.log('Navigating to apartment selection');
        navigateWithParams(ROUTES.APARTMENT_SELECTION, { buildingId });
      } else
      {
        console.log('Navigating to dashboard');
        navigateWithParams(ROUTES.DASHBOARD);
      }

      return true; // Indicate successful login
    } catch (error)
    {
      console.error("Login error:", error);
      return false; // Indicate failed login
    }
  }, [setUser, trackLogin, navigateWithParams, getParams]);

  const logout = useCallback(async (): Promise<void> => {
    try
    {
      const { error } = await enhancedAuthService.signOut();
      if (error) throw error;

      setUser(null);
      trackLogout();
      clearParams();
      navigateWithParams(ROUTES.LOGIN);
    } catch (error)
    {
      console.error("Logout error:", error);
    }
  }, [setUser, trackLogout, clearParams, navigateWithParams]);

  const signUpWithOtp = useCallback(async (email: string, password: string) => {
    return authService.signUpWithOtp(email, password);
  }, []);

  const verifyOtp = useCallback(async (email: string, token: string) => {
    return authService.verifyOtp(email, token);
  }, []);

  const updateUserPassword = useCallback(async (password: string) => {
    return authService.updateUserPassword(password);
  }, []);

  const updateUserProfile = useCallback(async (userId: string, userData: any) => {
    return authService.updateUserProfile(userId, userData);
  }, []);

  const completeRegistration = useCallback(async (email: string, userInfo: any) => {
    try
    {
      console.log('Starting registration completion for email:', email);

      // Get current session through authTokenService
      const session = await authTokenService.getSession();
      if (!session?.user?.id)
      {
        throw new Error('No valid user session found');
      }

      // Update profile
      console.log('Updating user profile...');
      const updatedProfile = await authService.updateUserProfile(session.user.id, {
        ...userInfo,
        email: email
      });

      // Construct full user
      const fullUser = {
        ...session.user,
        ...updatedProfile,
      };

      // Update global state
      setUser(fullUser);

      // Track and navigate
      trackRegister(fullUser.id, { email: fullUser.email });

      const { invitationToken, buildingId } = getParams();
      if (invitationToken)
      {
        await invitationService.createVisitFromInvitation(invitationToken, fullUser.id);
      }

      navigateWithParams(buildingId ? ROUTES.APARTMENT_SELECTION : ROUTES.DASHBOARD,
        buildingId ? { buildingId } : {});

      return fullUser;
    } catch (error)
    {
      console.error('Registration completion error:', error);
      throw error;
    }
  }, [getParams, navigateWithParams, trackRegister, setUser]);


  return {
    user,
    loading,
    login,
    logout,
    signUpWithOtp,
    verifyOtp,
    updateUserPassword,
    updateUserProfile,
    completeRegistration,
    setUser
  };
};
