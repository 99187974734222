
import { supabase } from '../client';

class RealtimeService {
    channels: Map<string, any>;
    isConnected: boolean;
    connectionRetries: number;
    maxRetries: number;
    retryDelay: number;

    constructor() {
    this.channels = new Map();
    this.isConnected = false;
    this.connectionRetries = 0;
    this.maxRetries = 3;
    this.retryDelay = 2000; // 2 seconds
  }

  async connect() {
    if (this.isConnected) return;

    try {
      await supabase.channel('system').subscribe((status) => {
        if (status === 'SUBSCRIBED') {
          this.isConnected = true;
          this.connectionRetries = 0;
          console.log('Realtime connection established');
        }
      });
    } catch (error) {
      console.error('Realtime connection error:', error);
      this.handleConnectionError();
    }
  }

  async handleConnectionError() {
    if (this.connectionRetries < this.maxRetries) {
      this.connectionRetries++;
      console.log(`Retrying connection (${this.connectionRetries}/${this.maxRetries})`);
      
      setTimeout(() => {
        this.connect();
      }, this.retryDelay * this.connectionRetries);
    } else {
      console.error('Max connection retries reached');
      this.isConnected = false;
    }
  }

  async disconnect() {
    try {
      // Remove all channels
      for (const [channelName, channel] of this.channels) {
        await channel.unsubscribe();
        this.channels.delete(channelName);
      }

      this.isConnected = false;
      console.log('Realtime connection closed');
    } catch (error) {
      console.error('Error disconnecting realtime:', error);
    }
  }

  async subscribeToChannel(channelName: string, options = {}) {
    if (this.channels.has(channelName)) {
      return this.channels.get(channelName);
    }

    try {
      const channel = supabase.channel(channelName, {
        ...options,
        config: {},
      });
      
      channel
        .on('presence', { event: 'sync' }, () => {
          console.log('Channel presence sync:', channelName);
        })
        .on('presence', { event: 'join' }, ({ key, newPresences }) => {
          console.log('Join:', key, newPresences);
        })
        .on('presence', { event: 'leave' }, ({ key, leftPresences }) => {
          console.log('Leave:', key, leftPresences);
        })
        .subscribe((status) => {
          console.log(`Channel ${channelName} status:`, status);
        });

      this.channels.set(channelName, channel);
      return channel;
    } catch (error) {
      console.error(`Error subscribing to channel ${channelName}:`, error);
      throw error;
    }
  }

  getChannel(channelName: string) {
    return this.channels.get(channelName);
  }
}

export const realtimeService = new RealtimeService();