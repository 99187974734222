// src/supabase/secureApi.ts

import { supabase } from './client';
import { authTokenService } from '../features/auth/services/authTokenService';

export const secureApi = {
  async ensureAuth() {
    if (!authTokenService.isAuthenticated()) {
      throw new Error('No active session');
    }
    await authTokenService.init();
  },

  handleError(error: any) {
    if (error.status === 401 || error.message?.includes('JWT')) {
      authTokenService.clearSession();
    }
    console.error('API error:', error);
  },

  async get(table: string, options: any = {}) {
    return authTokenService.retryOperation(async () => {
      await this.ensureAuth();
      
      let query: any = supabase
        .from(table)
        .select(options.select || '*');
        
      if (options.filter) {
        Object.entries(options.filter).forEach(([key, value]) => {
          query = query.eq(key, value);
        });
      }

      if (options.single) {
        query = query.single();
      }

      if (options.order) {
        Object.entries(options.order).forEach(([key, direction]) => {
          query = query.order(key, { ascending: direction === 'asc' });
        });
      }

      const { data, error } = await query;
      
      if (error) {
        this.handleError(error);
        throw error;
      }
      
      return data;
    });
  },

  async post(table: string, data: any) {
    return authTokenService.retryOperation(async () => {
      await this.ensureAuth();
      const { data: result, error } = await supabase
        .from(table)
        .insert(data)
        .select()
        .single();

      if (error) throw error;
      return result;
    });
  },

  async update(table: string, id: string, data: any) {
    return authTokenService.retryOperation(async () => {
      await this.ensureAuth();
      const { data: result, error } = await supabase
        .from(table)
        .update(data)
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;
      return result;
    });
  },

  async delete(table: string, id: string) {
    return authTokenService.retryOperation(async () => {
      await this.ensureAuth();
      const { error } = await supabase
        .from(table)
        .delete()
        .eq('id', id);

      if (error) throw error;
    });
  },

  async rpc(functionName: string, params: any) {
    return authTokenService.retryOperation(async () => {
      await this.ensureAuth();
      console.log(`Calling secure RPC function: ${functionName} with params:`, params);
      
      const { data, error } = await supabase.rpc(functionName, params);

      if (error) {
        console.error(`RPC error in function ${functionName}:`, error);
        throw error;
      }

      console.log(`RPC function ${functionName} returned:`, data);
      return data;
    });
  }
};