// src/features/auth/services/enhancedAuthService.ts

import { supabase } from '../../../supabase/client';
import { authTokenService } from './authTokenService';
import { authService } from './authService';

interface SignInWithPasswordParams {
  email: string;
  password: string;
}

interface SignInResponse {
  data?: {
    user: object;
    session: object;
  };
  error?: Error;
}

class EnhancedAuthService {
  async signInWithPassword({ email, password }: SignInWithPasswordParams): Promise<SignInResponse> {
    try {
      console.log('Starting login process for email:', email);

      const { data: { session }, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;
      if (!session) throw new Error('No session returned from sign-in');

      // Use authTokenService to manage session
      await authTokenService.setSession(session);

      const userData = await authService.getUserProfile(session.user.id).catch(err => {
        console.error('Error fetching user profile:', err);
        return null;
      });

      return {
        data: {
          user: { ...session.user, profile: userData },
          session,
        },
      };
    } catch (error: any) {
      console.error('Sign in error:', error);
      return { error };
    }
  }

  async signOut(): Promise<{ error: Error | null }> {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;

      authTokenService.clearSession();
      return { error: null };
    } catch (error: any) {
      console.error('Sign out error:', error);
      return { error };
    }
  }

  async getCurrentSession(): Promise<object | null> {
    return authTokenService.getSession();
  }

  getAuthHeaders(): Record<string, string> {
    const token = authTokenService.getAccessToken();
    return token
      ? {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      : {};
  }
}

export const enhancedAuthService = new EnhancedAuthService();
