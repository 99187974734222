// src/styles/featuresStyles/incidentStyles.ts

export const incidentStyles = {
    container: {
      py: 4,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      mb: 4,
    },
    headerTitle: {
      fontWeight: 'bold',
    },
    addButton: {
      borderRadius: '28px',
      px: 3,
      py: 1.5,
    },
    searchField: {
      mb: 3,
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'rgba(0,0,0,0.1)',
        },
        '&:hover fieldset': {
          borderColor: 'rgba(0,0,0,0.2)',
        },
      },
    },
    tabs: {
      mb: 3,
      '& .MuiTab-root': {
        fontWeight: 500,
        fontSize: '1rem',
      },
    },
    listContainer: {
      mt: 2,
    },
  };
  
  export const statusBadgeStyles = {
    pending: {
      backgroundColor: 'status.pending.light',
      color: 'status.pending.main',
    },
    resolved: {
      backgroundColor: 'status.resolved.light',
      color: 'status.resolved.main',
    },
    base: {
      px: 2,
      py: 1,
      borderRadius: '16px',
      fontSize: '0.875rem',
      fontWeight: 500,
    },
  };