// src/features/auth/components/EmailConfirmation.tsx

import React, { useState, useRef, useEffect } from 'react';
import { TextField, Button, Box, Typography, Link, CircularProgress, Paper, Fade } from '@mui/material';
import { styled } from '@mui/material/styles';
import { supabase } from '../../../supabase/client';
import EmailIcon from '@mui/icons-material/Email';

// Estilos personalizados
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  maxWidth: '400px',
  margin: 'auto',
}));

const OtpInput = styled(TextField)(({ theme }) => ({
  width: '3rem',
  '& input': {
    textAlign: 'center',
    fontSize: '1.5rem',
    padding: theme.spacing(1),
  },
}));

const ResendLink = styled(Link)(({ theme, disabled }: { theme: any; disabled: boolean }) => ({
  textDecoration: 'none',
  color: disabled ? theme.palette.text.disabled : theme.palette.primary.main,
  pointerEvents: disabled ? 'none' : 'auto',
  cursor: disabled ? 'default' : 'pointer',
  '&:hover': {
    textDecoration: disabled ? 'none' : 'underline',
  },
}));

// Tipos de las props
interface EmailConfirmationProps {
  email: string;
  onConfirmed: (token: string) => Promise<void>;
  onBack: () => void;
}

const EmailConfirmation: React.FC<EmailConfirmationProps> = ({ email, onConfirmed, onBack }) => {
  const [token, setToken] = useState<string[]>(['', '', '', '', '', '']);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [resendTimer, setResendTimer] = useState<number>(60);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [resendTimer]);

  const handleChange = (index: number, value: string) => {
    if (!/^[0-9]*$/.test(value)) return;

    const newToken = [...token];
    newToken[index] = value;
    setToken(newToken);

    if (value !== '' && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').replace(/\D/g, '').slice(0, 6);
    const newToken = [...token];
    for (let i = 0; i < 6; i++) {
      newToken[i] = pastedData[i] || '';
    }
    setToken(newToken);

    const lastIndex = newToken.findLastIndex((t) => t !== '');
    const focusIndex = lastIndex < 5 ? lastIndex + 1 : 5;
    inputRefs.current[focusIndex]?.focus();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    const tokenString = token.join('');
    try {
      await onConfirmed(tokenString);
    } catch (error: any) {
      console.error('OTP verification error:', error);
      alert(error.message || 'Error al verificar el código');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResend = async () => {
    if (resendTimer > 0) return;
    try {
      setIsSubmitting(true);
      const { error } = await supabase.auth.signUp({
        email,
        password: Math.random().toString(36).slice(-8),
      });
      if (error) throw error;
      alert('Nuevo código enviado. Por favor revisa tu correo.');
      setResendTimer(60);
    } catch (error: any) {
      console.error('Resend OTP error:', error);
      alert(error.message || 'Error al reenviar el código');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Fade in={true}>
      <StyledPaper elevation={3}>
        <Box component="form" onSubmit={handleSubmit} sx={{ textAlign: 'center' }}>
          <EmailIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
          <Typography variant="h5" gutterBottom fontWeight="bold">
            Verifica tu correo electrónico
          </Typography>
          <Typography variant="body1" gutterBottom color="text.secondary">
            Hemos enviado un código de 6 dígitos a:
          </Typography>
          <Typography variant="body1" gutterBottom fontWeight="medium">
            {email}
          </Typography>
          <Box display="flex" justifyContent="center" my={4}>
            {token.map((digit, index) => (
              <OtpInput
                key={index}
                inputRef={(el) => (inputRefs.current[index] = el)}
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                onPaste={handlePaste}
                inputProps={{
                  maxLength: 1,
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
                sx={{ mx: 0.5 }}
              />
            ))}
          </Box>
          <Box sx={{ mt: 4 }}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={isSubmitting || token.some((t) => t === '')}
              sx={{ mb: 2 }}
            >
              {isSubmitting ? <CircularProgress size={24} /> : 'Verificar'}
            </Button>
            <Button onClick={onBack} variant="outlined" fullWidth disabled={isSubmitting}>
              Cambiar correo
            </Button>
          </Box>
          <Box mt={3}>
            <ResendLink
              variant="body2"
              onClick={handleResend}
              disabled={resendTimer > 0 || isSubmitting} 
              theme={undefined}            >
              {resendTimer > 0 ? `Reenviar código (${resendTimer}s)` : 'Reenviar código'}
            </ResendLink>
          </Box>
        </Box>
      </StyledPaper>
    </Fade>
  );
};

export default EmailConfirmation;
