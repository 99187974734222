// src/features/incident/components/shared/StatusBadge.tsx

import { Chip } from '@mui/material';
import { 
  AlertCircle, 
  Clock, 
  CheckCircle2, 
  XCircle,
  RefreshCw,
  UserCheck,
  Archive
} from 'lucide-react';

const STATUS_CONFIG: Record<string, any> = {
  new: {
    label: 'Nuevo',
    icon: AlertCircle,
    color: 'info'
  },
  assigned: {
    label: 'Asignado',
    icon: UserCheck,
    color: 'secondary'
  },
  in_progress: {
    label: 'En proceso',
    icon: RefreshCw,
    color: 'primary'
  },
  pending_review: {
    label: 'Pendiente revisión',
    icon: Clock,
    color: 'warning'
  },
  resolved: {
    label: 'Resuelto',
    icon: CheckCircle2,
    color: 'success'
  },
  closed: {
    label: 'Cerrado',
    icon: Archive,
    color: 'default'
  },
  cancelled: {
    label: 'Cancelado',
    icon: XCircle,
    color: 'error'
  }
};

const StatusBadge = ({ status, size = 'small' }: { status: string; size?: 'small' | 'medium'}) => {
  const config = STATUS_CONFIG[status] || STATUS_CONFIG.new;
  const IconComponent = config.icon;

  return (
    <Chip
      icon={<IconComponent size={size === 'small' ? 14 : 16} />}
      label={config.label}
      color={config.color}
      size={size}
      sx={{
        '& .MuiChip-icon': {
          marginLeft: '8px'
        }
      }}
    />
  );
};

export default StatusBadge;