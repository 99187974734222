// src/features/incident/components/create/MediaUpload.js

import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Stack
} from '@mui/material';
import {
  AttachFile as AttachIcon,
  Close as DeleteIcon
} from '@mui/icons-material';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4'];

const MediaUpload = ({ files, onChange, disabled, hideAttachButton = false }: {files: File[], onChange: (files: File[]) => void, disabled?: boolean, prefix?: string, hideAttachButton?: boolean}) => {

  const validateFile = (file: File) => {
    if (!ALLOWED_TYPES.includes(file.type)) {
      throw new Error('Tipo de archivo no permitido');
    }
    if (file.size > MAX_FILE_SIZE) {
      throw new Error(`El archivo ${file.name} excede el tamaño máximo de 5MB`);
    }
  };

  const handleFileSelect = (event: any) => {
    const selectedFiles: File[] = Array.from(event.target.files);
    
    try {
      selectedFiles.forEach(validateFile);
      onChange([...files, ...selectedFiles]);
    } catch (error: any) {
      alert(error.message);
    }
    event.target.value = '';
  };

  const handleRemoveFile = (index: number) => {
    onChange(files.filter((_, i) => i !== index));
  };

  return (
    <Stack spacing={2}>
      {!hideAttachButton && (
        <Button
          variant="outlined"
          component="label"
          fullWidth
          startIcon={<AttachIcon />}
          disabled={disabled}
        >
          Adjuntar archivos
          <input
            type="file"
            hidden
            multiple
            onChange={handleFileSelect}
            accept={ALLOWED_TYPES.join(',')}
          />
        </Button>
      )}
      
      {files.length > 0 && (
        <List>
          {files.map((file, index) => (
            <ListItem key={index} dense>
              <ListItemText
                primary={file.name}
                secondary={`${(file.size / 1024 / 1024).toFixed(2)} MB`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  onClick={() => handleRemoveFile(index)}
                  disabled={disabled}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </Stack>
  );
};

export default MediaUpload;