// src/features/incident/pages/IncidentMain.tsx

import { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Alert,
  CircularProgress,
  Tabs,
  Tab,
} from '@mui/material';
import { Plus } from 'lucide-react';
import { IncidentList, IncidentTable } from '../components/list';
import { useIncident } from '../hooks/useIncident';
import { useNavigation } from '../../../navigation/hooks/useNavigation';
import { ROUTES } from '../../../config/routes';
import { incidentService } from '../services/incidentService';
import { isWithinInterval, startOfWeek, endOfWeek } from 'date-fns';
import { incidentStyles } from '../../../styles/featuresStyles/incidentStyles';
import useGlobalStore from '../../../state/store';

const IncidentMain = () => {
  const { navigateWithParams, getParams } = useNavigation();
  const { updateIncidentStatus } = useIncident();
  const [incidents, setIncidents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const user = useGlobalStore((state) => state.user);

  const fetchIncidents = useCallback(async () => {
    try
    {
      setLoading(true);
      setError(null);

      if (!user?.id)
      {
        console.warn('No user ID found');
        setLoading(false);
        return;
      }

      const response = await incidentService.fetchUserIncidents(user.id);
      console.log('Raw API Response:', response);

      // Check if response is an array directly or has data property
      const incidents = Array.isArray(response) ? response : response?.data;

      if (!incidents)
      {
        console.warn('Invalid response format:', response);
        setIncidents([]);
        return;
      }

      console.log('Processed incidents:', {
        total: incidents.length,
        sample: incidents[0]
      });

      setIncidents(incidents);
    } catch (err: any)
    {
      console.error('Error fetching incidents:', err);
      setError(err.message || 'Error loading incidents');
    } finally
    {
      setLoading(false);
    }
  }, [user?.id]);

  useEffect(() => {
    fetchIncidents();
  }, [fetchIncidents]);

  const handleStatusChange = useCallback(async (incidentId: any, newStatus: any) => {
    try
    {
      await updateIncidentStatus(incidentId, newStatus);
      const response = await incidentService.fetchUserIncidents(user?.id);
      setIncidents(response || []);
    } catch (err: any)
    {
      setError(err.message || 'Error updating incident status');
    }
  }, [updateIncidentStatus, user?.id]);

  const handleIncidentClick = useCallback((incidentId: any) => {
    const params = getParams();
    navigateWithParams(`${ROUTES.INCIDENTS}/${incidentId}`, params);
  }, [getParams, navigateWithParams]);

  const handleTabChange = (_event: any, newValue: any) => {
    setTabValue(newValue);
  };

  const isWithinCurrentWeek = useCallback((date: any) => {
    if (!date) return false;
    const now = new Date();
    const weekStart = startOfWeek(now);
    const weekEnd = endOfWeek(now);

    return isWithinInterval(new Date(date), { start: weekStart, end: weekEnd });
  }, []);

  const { activeIncidents, resolvedIncidents } = useMemo(() => {
    const active = incidents.filter((inc: any) =>
      !inc.resolution_date || isWithinCurrentWeek(inc.resolution_date)
    );
    const resolved = incidents.filter((inc: any) =>
      inc.resolution_date && !isWithinCurrentWeek(inc.resolution_date)
    );
    return { activeIncidents: active, resolvedIncidents: resolved };
  }, [incidents, isWithinCurrentWeek]);

  const handleCreateNew = () => {
    const params = getParams();
    navigateWithParams(ROUTES.INCIDENT_NEW, params);
  };

  if (loading)
  {
    return (
      <Box display="flex" justifyContent="center" py={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={incidentStyles.container}>
        <Box sx={incidentStyles.header}>
          <Typography
            variant="h5"
            component="h1"
            sx={incidentStyles.headerTitle}
          >
            Mis Incidencias
          </Typography>
          <Button
            variant="contained"
            startIcon={<Plus />}
            onClick={handleCreateNew}
            sx={incidentStyles.addButton}
          >
            Nueva Incidencia
          </Button>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="incident views"
          sx={incidentStyles.tabs}
          centered
        >
          <Tab label="En Proceso" />
          <Tab label="Resueltas" />
        </Tabs>

        <Box sx={incidentStyles.listContainer}>
          {tabValue === 0 ? (
            <IncidentList
              incidents={activeIncidents}
              onStatusChange={handleStatusChange}
              onIncidentClick={handleIncidentClick}
            />
          ) : (
            <IncidentTable
              incidents={resolvedIncidents}
              onStatusChange={handleStatusChange}
              onIncidentClick={handleIncidentClick}
            />
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default IncidentMain;