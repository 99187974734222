// src/features/invitation/pages/InvitationLanding.tsx

import { useEffect, useState } from 'react';
import { Box, Typography, Button, Avatar, Stack, CircularProgress, Divider, Snackbar } from '@mui/material';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { CalendarToday, AccessTime, LocationOn, Apartment } from '@mui/icons-material';
import { invitationService } from '../services/invitationService';
import { useInvitation } from '../hooks/useInvitation';
import { useAuth } from '../../auth/hooks/useAuth';
import { useNavigation } from '../../../navigation/hooks/useNavigation';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useInvitationAnalytics } from '../hooks/useInvitationAnalytics';
import { ROUTES } from '../../../config/routes';

const InvitationLanding = () => {
  const { setInvitationToken, createVisitFromInvitation } = useInvitation();
  const [invitation, setInvitation] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { inviteToken } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { trackInvitationViewed } = useInvitationAnalytics();
  const { navigateWithParams } = useNavigation();

  useEffect(() => {
    if (inviteToken) {
      setInvitationToken(inviteToken);
      const fetchInvitationData = async () => {
        try {
          const data = await invitationService.fetchInvitation(inviteToken);
          setInvitation(data);
          if (user) {
            trackInvitationViewed(data.id, user.id);
          }
        } catch (err: any) {
          console.error('Error fetching invitation:', err);
          setError(err.message || "An error occurred while fetching the invitation");
        } finally {
          setLoading(false);
        }
      };

      fetchInvitationData();
    } else {
      setError("No invitation token provided");
      setLoading(false);
    }
  }, [inviteToken, setInvitationToken, user, trackInvitationViewed]);

  const handleContinue = async () => {
    if (!user) {
      setError("User not authenticated");
      return;
    }
    try {
      const { isExisting } = await createVisitFromInvitation();
      if (isExisting) {
        setSnackbarMessage('Ya has aceptado esta invitación anteriormente.');
      } else {
        setSnackbarMessage('Invitación aceptada exitosamente.');
      }
      setSnackbarOpen(true);
      // Navigate immediately instead of using setTimeout
      navigate(ROUTES.DASHBOARD);
    } catch (error: any) {
      console.error('Error processing invitation:', error);
      setError('Error al procesar la invitación: ' + error.message);
      setSnackbarMessage('Ocurrió un error al procesar la invitación. Por favor, intenta de nuevo.');
      setSnackbarOpen(true);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  if (!invitation) {
    return <Typography>Invitación no encontrada.</Typography>;
  }

  const residentName = invitation.resident ? `${invitation.resident.first_name} ${invitation.resident.last_name}` : 'Unknown';
  const visitDate = invitation.date ? new Date(invitation.date) : null;

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', p: 2 }}>
      <Typography variant="h4" gutterBottom>Bienvenido</Typography>
      <Typography variant="body1" paragraph>
        Te han invitado a realizar una visita. {user ? 'Continúa para confirmar tu asistencia.' : 'Para confirmar tu asistencia, inicia sesión o regístrate.'}
      </Typography>
      <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>Invitación de:</Typography>
        <Stack direction="row" spacing={2} alignItems="center" mb={2}>
          <Avatar src={invitation.resident?.profile_pic} alt={residentName}>
            {residentName.charAt(0)}
          </Avatar>
          <Box>
            <Typography variant="subtitle1">{residentName}</Typography>
            {visitDate && (
              <>
                <Stack direction="row" spacing={1} alignItems="center">
                  <CalendarToday fontSize="small" />
                  <Typography variant="body2">
                    {format(visitDate, "EEEE d 'de' MMMM, yyyy", { locale: es })}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <AccessTime fontSize="small" />
                  <Typography variant="body2">
                    {format(visitDate, "HH:mm")}
                  </Typography>
                </Stack>
              </>
            )}
          </Box>
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" gutterBottom>Lugar de la visita:</Typography>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Apartment fontSize="small" />
            <Typography variant="body2">
              Apartamento: {invitation.apartment?.apartment_number}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="flex-start">
            <LocationOn fontSize="small" sx={{ mt: 0.5 }} />
            <Box>
              <Typography variant="body2">
                {invitation.apartment?.building?.name}
              </Typography>
              <Typography variant="body2">
                {invitation.apartment?.building?.address}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>
      {user ? (
        <Button
          onClick={handleContinue}
          variant="contained"
          fullWidth
        >
          Continuar
        </Button>
      ) : (
        <>
          <Button
            onClick={() => navigateWithParams(ROUTES.LOGIN, { token: inviteToken })}
            variant="contained"
            fullWidth
            sx={{ mb: 2 }}
          >
            Iniciar sesión
          </Button>
          <Button
            onClick={() => navigateWithParams(ROUTES.REGISTER, { token: inviteToken })}
            variant="outlined"
            fullWidth
          >
            Soy nuevo, registrarme
          </Button>
        </>
      )}
      <Typography variant="body2" align="center" sx={{ mt: 2 }}>
        Al iniciar sesión o registrarse, acepta nuestros{' '}
          <Link to={ROUTES.TERMS} target="_blank" rel="noopener noreferrer">
            Términos de servicio
          </Link>{' '}
          y{' '}
          <Link to={ROUTES.PRIVACY} target="_blank" rel="noopener noreferrer">
            Política de Privacidad
          </Link>
      </Typography>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default InvitationLanding;