// src/features/auth/hooks/useAuthAnalytics.ts

import { useCallback } from 'react';
import { useAnalytics } from '../../../analytics/hooks/useAnalytics';
import { EVENT_TYPES } from '../../../analytics/config/events';

// Define types for the parameters and return types

interface UserInfo {
  email: string;
  [key: string]: any;  // Allow other dynamic properties
}

interface TrackRegisterStepProperties {
  step?: string;
  [key: string]: any;  // Allow other dynamic properties
}

export const useAuthAnalytics = () => {
  const { track, identify } = useAnalytics();

  // Track login event with user information and context (normal, invitation, building)
  const trackLogin = useCallback((userId: string, email: string, context: 'normal' | 'invitation' | 'building' = 'normal') => {
    identify(userId, { email });
    const eventName =
      context === 'invitation' ? EVENT_TYPES.LOGIN_FROM_INVITATION :
      context === 'building' ? EVENT_TYPES.LOGIN_FROM_BUILDING :
      EVENT_TYPES.LOGIN;
    track(eventName, { userId, email, context });
  }, [track, identify]);

  // Track registration event with user information and context
  const trackRegister = useCallback((userId: string, userInfo: UserInfo, context: 'normal' | 'invitation' | 'building' = 'normal') => {
    identify(userId, userInfo);
    const eventName =
      context === 'invitation' ? EVENT_TYPES.REGISTER_FROM_INVITATION :
      context === 'building' ? EVENT_TYPES.REGISTER_FROM_BUILDING :
      EVENT_TYPES.REGISTER;
    track(eventName, { userId, ...userInfo, context });
  }, [track, identify]);

  // Track registration step event with step info and additional properties
  const trackRegisterStep = useCallback((step: string, properties: TrackRegisterStepProperties = {}) => {
    track(`${EVENT_TYPES.REGISTER}_STEP`, { step, ...properties });
  }, [track]);

  // Track password reset request event
  const trackPasswordReset = useCallback((email: string) => {
    track(EVENT_TYPES.PASSWORD_RESET_REQUESTED, { email });
  }, [track]);

  // Track password reset completed event
  const trackPasswordResetCompleted = useCallback((userId?: string) => {
    track(EVENT_TYPES.PASSWORD_RESET_COMPLETED, { userId });
  }, [track]);

  // Track logout event
  const trackLogout = useCallback((userId?: string) => {
    track(EVENT_TYPES.LOGOUT, { userId });
  }, [track]);

  return {
    trackLogin,
    trackRegister,
    trackRegisterStep,
    trackPasswordReset,
    trackPasswordResetCompleted,
    trackLogout,
  };
};
