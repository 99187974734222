// src/features/navigation/context/NavigationContext.tsx

import { createContext, useState, useContext, ReactNode } from 'react';

// Definir el tipo de los parámetros de navegación
interface NavigationParams {
  [key: string]: any; // Puedes especificar un tipo más preciso dependiendo de los parámetros que esperas
}

// Definir el tipo del contexto
interface NavigationContextType {
  params: NavigationParams;
  setParams: React.Dispatch<React.SetStateAction<NavigationParams>>;
}

// Crear el contexto con un valor predeterminado (esto se puede mejorar si se conoce el tipo exacto)
const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

// Hook personalizado para acceder al contexto
export const useNavigationContext = (): NavigationContextType => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigationContext must be used within a NavigationProvider');
  }
  return context;
};

// Tipo de las propiedades del proveedor
interface NavigationProviderProps {
  children: ReactNode;
}

export const NavigationProvider = ({ children }: NavigationProviderProps) => {
  const [params, setParams] = useState<NavigationParams>({});

  const value = {
    params,
    setParams,
  };

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};
