// src/navigation/components/PrivateRoute.ts

import { useEffect, useState } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { ROUTES } from '../../config/routes';
import { useAnalytics } from '../../analytics/hooks/useAnalytics';
import { authTokenService } from '../../features/auth/services/authTokenService';

const PrivateRoute = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { track } = useAnalytics();

  useEffect(() => {
    let mounted = true;

    const checkAuth = async () => {
      try {
        await authTokenService.init();
        const session = authTokenService.getSession();
        
        if (mounted) {
          setIsAuth(!!session);
          if (!session) {
            track('UNAUTHORIZED_ACCESS_ATTEMPT', { path: location.pathname });
          }
        }
      } catch (error) {
        console.error('Auth check failed:', error);
        if (mounted) {
          setIsAuth(false);
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    checkAuth();

    return () => {
      mounted = false;
    };
  }, [location, track]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return isAuth ? <Outlet /> : <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
};

const AuthRoute = ({ children }: any) => {
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      await authTokenService.init();
      const session = authTokenService.getSession();
      setIsAuth(!!session);
      setLoading(false);
    };

    checkAuth();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return isAuth ? <Navigate to={ROUTES.DASHBOARD} replace /> : children;
};

const PublicAuthRoute = ({ children }: any) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      await authTokenService.init();
      setLoading(false);
    };

    initAuth();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return children;
};

export { PrivateRoute, AuthRoute, PublicAuthRoute };