import React, { useCallback } from 'react';
import {
  Container,
  Box,
  CircularProgress,
  Alert,
  IconButton,
  Typography
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useIncident } from '../hooks/useIncident';
import { useNavigation } from '../../../navigation/hooks/useNavigation';
import { ROUTES } from '../../../config/routes';
import IncidentDetailView from '../components/detail/IncidentDetailView';
import useGlobalStore from '../../../state/store';

const IncidentDetail = () => {
  const { id } = useParams();
  const { navigateWithParams, getParams } = useNavigation();
  const user = useGlobalStore((state) => state.user);
  const {
    incident,
    loading,
    error,
    addComment
  } = useIncident(id);

  const handleBack = () => {
    const params = getParams();
    navigateWithParams(ROUTES.INCIDENTS, params);
  };

  // TODO: Implement update and rate again.
  // const handleUpdateStatus = async (incidentId: any, newStatus: any) => {
  //   try {
  //     await updateIncidentStatus(incidentId, newStatus);
  //   } catch (err) {
  //     console.error('Error updating status:', err);
  //   }
  // };

  const handleComment = async (commentText: any, mediaFiles: any) => {
    try
    {
      console.log('Submitting comment:', {
        commentText,
        mediaFiles,
        buildingId: incident.building_id // Log the building_id
      });

      if (!incident.building_id)
      {
        throw new Error('Missing building ID');
      }

      await addComment(commentText, mediaFiles);
    } catch (err)
    {
      console.error('Error adding comment:', err);
    }
  };

  const handleRateResolution = useCallback((rating: any, comment: any) => {
    console.log('Rating:', rating, comment);
    // Implement rating logic here
  }, []);

  if (!user)
  {
    return (
      <Container maxWidth="lg">
        <Box py={4}>
          <Alert severity="warning">Please log in to view this incident.</Alert>
        </Box>
      </Container>
    );
  }

  if (loading)
  {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error)
  {
    return (
      <Container maxWidth="lg">
        <Box py={4}>
          <Alert severity="error">{error}</Alert>
        </Box>
      </Container>
    );
  }

  if (!incident)
  {
    return (
      <Container maxWidth="lg">
        <Box py={4}>
          <Alert severity="info">Incident not found</Alert>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box py={4}>
        <Box display="flex" alignItems="center" mb={3}>
          <IconButton onClick={handleBack} sx={{ mr: 2 }}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h5">
            {incident.title}
          </Typography>
        </Box>

        <IncidentDetailView
          incident={incident}
          onAddComment={handleComment}
          onRateResolution={handleRateResolution}
        />
      </Box>
    </Container>
  );
};

export default IncidentDetail;