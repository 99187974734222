// src/features/auth/pages/Login.js

import { useState } from 'react';
import { Typography, Box, Link, Snackbar } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import LoginForm from '../components/LoginForm';
import { useAuth } from '../hooks/useAuth';
import { useNavigation } from '../../../navigation/hooks/useNavigation';
import { ROUTES } from '../../../config/routes';

interface LoginCredentials {
  email: string;
  password: string;
}

const Login = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { login } = useAuth();
  const { getParams, navigateWithParams } = useNavigation();

  const handleLogin = async ({ email, password }: LoginCredentials) => {
    try {
      const success = await login(email, password);
      if (success) {
        setSnackbarMessage('Login successful');
      } else {
        setSnackbarMessage('Login failed');
      }
      setOpenSnackbar(true);
    } catch (error: any) {
      console.error('Login error:', error);
      setSnackbarMessage(error.message || "An error occurred during login");
      setOpenSnackbar(true);
    }
  };

  const params = getParams();

  return (
    <Box sx={{ mt: 1, maxWidth: 400, margin: 'auto', p: 2 }}>
      <Typography component="h1" variant="h5" gutterBottom>
        Iniciar sesión
      </Typography>
      <LoginForm onSubmit={handleLogin} />
      <Typography align="center">
        <Link component={RouterLink} to={ROUTES.FORGOT_PASSWORD} underline="hover">
          Olvidé mi contraseña
        </Link>
      </Typography>
      <Typography align="center" sx={{ mt: 1 }}>
        No tengo cuenta, <Link component={RouterLink} onClick={() => navigateWithParams(ROUTES.REGISTER, params)} to="#" underline="hover">Registrarme</Link>
      </Typography>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default Login;
