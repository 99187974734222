// src/features/building/hooks/useBuildingAnalytics.js

import { useCallback } from 'react';
import { useAnalytics } from '../../../analytics/hooks/useAnalytics';
import { EVENT_TYPES } from '../../../analytics/config/events';

interface UseBuildingAnalytics {
  trackBuildingViewed: (buildingId: string, userId: string) => void;
  trackApartmentSelected: (apartmentId: string, buildingId: string, userId?: string) => void;
  trackApartmentClaimed: (apartmentId: string, buildingId: string, userId?: string) => void;
}

export const useBuildingAnalytics = (): UseBuildingAnalytics => {
  const { track } = useAnalytics();

  const trackBuildingViewed = useCallback(
    (buildingId: string, userId: string): void => {
      track(EVENT_TYPES.BUILDING_VIEWED, { buildingId, userId });
    },
    [track]
  );

  const trackApartmentSelected = useCallback(
    (apartmentId: string, buildingId: string, userId?: string): void => {
      track(EVENT_TYPES.APARTMENT_SELECTED, { apartmentId, buildingId, userId });
    },
    [track]
  );

  const trackApartmentClaimed = useCallback(
    (apartmentId: string, buildingId: string, userId?: string): void => {
      track(EVENT_TYPES.APARTMENT_CLAIMED, { apartmentId, buildingId, userId });
    },
    [track]
  );

  return {
    trackBuildingViewed,
    trackApartmentSelected,
    trackApartmentClaimed,
  };
};
