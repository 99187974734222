// src/features/invitation/services/invitationService.js

import { secureApi } from '../../../supabase/secureApi';
import { supabase } from '../../../supabase/client';
import { authTokenService } from '../../../features/auth/services/authTokenService';
import { publicApi } from '../../../supabase/publicApi';

export const invitationService = {
  async fetchInvitation(token: any) {
    try
    {
      const isAuthenticated = !!authTokenService.getSession();
      const api = isAuthenticated ? secureApi : publicApi;

      console.log('Fetching invitation:', { token, isAuthenticated });

      // Use a single query with full joins for both auth and non-auth
      const invitation = await api.get('invitations', {
        select: `
          id,
          date,
          invitation_token,
          is_active,
          resident:users (
            first_name,
            last_name
          ),
          apartment:apartments (
            apartment_number,
            building:buildings (
              name,
              address
            )
          )
        `,
        filter: {
          invitation_token: token,
          is_active: true
        },
        single: true
      });

      if (!invitation)
      {
        console.log('No invitation found for token:', token);
        return null;
      }

      // Transform response to match expected format
      return {
        ...invitation,
        apartment: {
          apartment_number: invitation.apartment?.apartment_number || 'N/A',
          building: {
            name: invitation.apartment?.building?.name || 'N/A',
            address: invitation.apartment?.building?.address || 'N/A'
          }
        }
      };
    } catch (error)
    {
      console.error('Error fetching invitation:', error);
      throw error;
    }
  },

  async checkExistingVisit(invitationToken: any, userId: any) {
    try
    {
      const result = await secureApi.get('visits', {
        select: `
          id,
          invitation:invitations (
            invitation_token
          )
        `,
        filter: {
          'invitation.invitation_token': invitationToken,
          visitor_id: userId
        },
        single: true
      });
      return result;
    } catch (error: any)
    {
      if (error.code === 'PGRST116' || error.status === 406)
      {
        // This error code means no rows were returned, or the request was not acceptable
        // In both cases, we assume no existing visit
        return null;
      }
      console.error('Error checking existing visit:', error);
      throw error;
    }
  },

  async createVisitFromInvitation(invitationToken: any, userId: any) {
    try
    {
      console.log('Creating visit from invitation. Token:', invitationToken, 'User ID:', userId);

      // First, check if the visit already exists
      const existingVisit = await this.checkExistingVisit(invitationToken, userId);
      if (existingVisit)
      {
        console.log('Visit already exists:', existingVisit);
        return { visitId: existingVisit.id, isExisting: true };
      }

      // If no existing visit, create a new one
      const result = await secureApi.rpc('create_visit_from_invitation', {
        p_invitation_token: invitationToken,
        p_visitor_id: userId
      });

      if (!result)
      {
        throw new Error('No visit created');
      }

      console.log('Visit created successfully:', result);
      return { visitId: result, isExisting: false };
    } catch (error: any)
    {
      if (error.code === '23505')
      {
        // This is the error code for duplicate key violation
        console.log('Visit already exists (caught in create)');
        return { visitId: null, isExisting: true };
      }
      console.error('Error creating visit from invitation:', error);
      throw error;
    }
  },

  async fetchUserVisits() {
    try
    {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      // Get user's public ID
      const userData = await secureApi.get('users', {
        select: 'id',
        filter: { auth_user_id: user.id },
        single: true
      });

      // Simplified select query without deep nesting
      return secureApi.get('visits', {
        select: `
          id,
          status,
          invitation:invitations!inner (
            date,
            resident:users!inner (
              first_name,
              last_name
            ),
            apartment_id
          )
        `,
        filter: { visitor_id: userData.id },
        order: { created_at: 'desc' }
      });
    } catch (error)
    {
      console.error('Error fetching visits:', error);
      throw error;
    }
  },

  async fetchVisitDetails(visitId: any) {
    try
    {
      // Get all visit data in a single query with proper nesting
      const visit = await secureApi.get('visits', {
        select: `
          id,
          status,
          invitation:invitations!inner (
            date,
            resident:users!inner (
              first_name,
              last_name,
              profile_pic
            ),
            apartment:apartments!inner (
              apartment_number,
              building:buildings!inner (
                name,
                address
              )
            )
          )
        `,
        filter: { id: visitId },
        single: true
      });

      if (!visit)
      {
        throw new Error('Visit not found');
      }

      // Return with proper structure and fallbacks
      return {
        ...visit,
        invitation: {
          ...visit.invitation,
          apartment: {
            apartment_number: visit.invitation?.apartment?.apartment_number || 'N/A',
            building: {
              name: visit.invitation?.apartment?.building?.name || 'N/A',
              address: visit.invitation?.apartment?.building?.address || 'N/A'
            }
          }
        }
      };
    } catch (error)
    {
      console.error('Error fetching visit details:', error);
      throw error;
    }
  }
};