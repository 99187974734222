// src/shared/utils/urlNormalizer.ts

export const normalizeUrl = (path: string) => {
    const parts = path.split('/').filter(Boolean);
    
    const normalizedParts = parts.map((part, index) => {
      // Normalize the first part after the base path
      if (index === 1) {
        switch (parts[0]) {
          case 'building':
            return ':buildingId';
          case 'invite':
            return ':inviteToken';
          default:
            return part;
        }
      }
      return part;
    });
  
    return '/' + normalizedParts.join('/');
  };