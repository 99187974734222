// src/features/incident/components/detail/ResolutionRating.js
import React, { useState, useCallback } from 'react';
import {
  Box,
  Typography,
  Rating,
  Button,
  TextField,
  Stack,
} from '@mui/material';
import { Star } from 'lucide-react';

interface ResolutionRatingProps {
  incidentId: string;
  onRate: (rating: number, comment: string) => Promise<void>;
}

const ResolutionRating: React.FC<ResolutionRatingProps> = React.memo(({ incidentId, onRate }) => {
  const [rating, setRating] = useState<number | null>(0);
  const [comment, setComment] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = useCallback(async (e: any) => {
    e.preventDefault();
    if (!rating) return; // Validación si no hay calificación

    try
    {
      setSubmitted(true);
    } catch (error)
    {
      console.error('Error submitting rating:', error);
    }
  }, [incidentId, rating, comment, onRate]);

  if (submitted)
  {
    return (
      <Box textAlign="center" p={2}>
        <Typography variant="h6" gutterBottom>
          Thank you for your feedback!
        </Typography>
      </Box>
    );
  }

  return (
    <Box component="form" onSubmit={handleSubmit} p={2}>
      <Typography variant="h6" gutterBottom>
        Rate the resolution
      </Typography>
      <Stack spacing={2}>
        <Rating
          name="resolution-rating"
          value={rating}
          onChange={(e, newValue) => setRating(newValue)}
          icon={<Star fontSize="inherit" />}
          emptyIcon={<Star fontSize="inherit" />}
        />
        <TextField
          label="Comment"
          multiline
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </Stack>
    </Box>
  );
});

export default ResolutionRating;
